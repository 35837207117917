<!-- 返回顶部 -->

<template>
  <div>
    <div class="btn" @click="toTop">
      <el-button type="info" icon="el-icon-top" circle></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "backTOP",
  data(){
    return {

    }
  },

  mounted(){

  },

  methods: {
    toTop(){
      document.documentElement.scrollTop = 0
			document.body.scrollTop = 0
    },
  }
}
</script>

<style lang="less" scoped>
.btn {
  position: fixed;
  z-index: 99;
  top: 55vh;
  right: 0;
  color: #ffffff;
  font-weight: bold;
}
</style>