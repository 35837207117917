// import Vue from "vue"; //引入vue
// import VueI18n from "vue-i18n"; //引入vue-i18n

import Vue from "vue"; //引入vue
import VueI18n from "vue-i18n"; //引入vue-i18n

import zhCnLocale from "@/lang/zh-cn"; //本地中文简体语言包
import zhHkLocale from "@/lang/zh-hk"; //本地中文繁体语言包
import enLocale from "@/lang/en"; //本地英文语言包

Vue.use(VueI18n);

// 语音包整理
const messages = {
  // 中文简体
  "zh-cn": {
    ...zhCnLocale,
  },
  // 中文繁体
  "zh-hk": {
    ...zhHkLocale,
  },
  // 英文
  "en": {
    ...enLocale,
  },
};


// 创建国际化实例
const i18n = new VueI18n({
  locale: localStorage.getItem('currentLang') || process.env.VUE_APP_DEFAULT_LANG || 'zh-cn', //设置默认语言
  // locale: localStorage.getItem('currentLang') || 'en', //设置默认语言
  messages
})

// vue-i18n 兼容 element国际化、Avue国际化
// elementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;