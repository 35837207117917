<template>
  <div>
    <!-- 头部 -->
    <common-nav @clickBtn="toFooterWrap" @MeiyeDropdown="MeiyeDropdown" />
    <!-- 广告 -->
    <custom-banner
      :data="bannerData"
      :mode="2"
      layout="left"
      theme="#04CB94"
      text_color="black"
      :border-radius="50"
      :is-cns="isCns"
      @clickBtn="toFooterWrap"
    ></custom-banner>
    <!-- 皮肤检测组件 -->
    <skindetection v-if="type == 'skin'"></skindetection>
    <!-- 体质检测 -->
   <Physicalexamination v-if="type == 'constitution'"></Physicalexamination>
   <!-- 体态检测 -->
   <Physicalfitness v-if="type == 'posture'"></Physicalfitness>

   <!-- 回到顶部 -->
   <backTOP />
    <!-- 页脚 -->
    <div id="footer">
      <custom-footer />
    </div>
  </div>
</template>

<script>
// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue";
// 广告图组件
import customBanner from "@/components/customBanner/index.vue";
//皮肤检测组件
import skindetection from "./components/skindetection.vue";
//体质检测组件
import Physicalexamination from "./components/Physicalexamination.vue";
//体态检测组件
import Physicalfitness from "./components/Physicalfitness.vue";

// 页脚
import customFooter from "@/components/customFooter/index.vue";

export default {
  name: "OfficialMeiyewebIndex",
  components: {
    commonNav,
    customBanner,
   skindetection,
   Physicalexamination,
   Physicalfitness,
    customFooter,
  },
  data() {
    return {
      isCns: false,
      type: "skin", //导航选择类型  //默认是皮肤检测

    };
  },
  computed: {
    bannerData() {
      let type = this.type;
      let bannerArr = [];
      // 皮肤检测轮播内容
      if (type == "skin") {
        bannerArr = [
          {
            title: "AI皮肤检测",
            banner:
              "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/baafdbf6-f7b2-452d-94e3-9917bf383b93.png",
            subtitle: "AI算法驱动品牌业绩增长,赋能品牌提升消费者购物体验",
            content: ["黑科技深度绑定消费者，重新定义流量获取到流量变现"],
            button: "免费试用",
          },
        ];
      } else if (type == 'constitution') {  //体质检测
       bannerArr = [
          {
            title: "AI体质检测",
            banner:
              "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/4692490d-2ccc-46e2-a2b5-764d45561de5.png",
            subtitle: "人工智能赋能中医诊疗",
            content: ["智能体质辨识、智能导诊、在线问诊、辅助诊疗、营销经营全方位一体的AI经营方案"],
            button: "免费体验",
          },
        ];
      } else if (type == 'posture') {  //体态检测
       bannerArr = [
          {
            title: "AI体态检测",
            banner:
              "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/bcdabfef-b204-4008-92a5-fd0f680d0770.png",
            subtitle: "人体关键点识别实现体态智能检测",
            content: ["精准定位核心关键点坐标、通过计算肩部、脊柱、骨盆、腿部等体态数据，快速给到用户专业的诊断报告，并提供相应的康复方案"],
            button: "免费体验",
          },
        ];
      }
      return bannerArr;
    },
  },
  mounted() {
   let name = this.$route.query.name
     if (name) {
    console.log(name);
      this.type = name;
       }

  },

  methods: {
    /**
     * 锚点定位
     */
    toFooterWrap() {
      document.querySelector("#footer").scrollIntoView({
        behavior: "smooth",
      });
    },
   MeiyeDropdown(value) {
    this.type = value
     console.log(this.type);
    },
  },
};
</script>

<style lang="scss" scoped></style>
