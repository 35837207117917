<template>
  <div>
    <div class="skind-box">
      <div class="container flexbox">
        <div class="title">一次性扫描检测30+维度</div>
        <div class="detail">
          趋势云独特影像算法，20s内完成30个肌肤维度检测，检测更全面
        </div>
        <div style="width: 70vw;">
         <el-image
          style="width: 100%; height: auto; margin-top: 57px"
          src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/1979ff00-35b4-4e70-b2ca-8c0435ad93da.png"
          fit="contain"
        ></el-image>
        </div>
      </div>
    </div>
    <div class="skind-boxd">
      <div class="container flexbox">
        <div class="title">AI皮肤分析+产品推荐</div>
        <div class="detail">
          每位会员都将拥有一个超高效率的AI护肤专家顾问，服务更极致
        </div>

         <el-image
          style="width: 1222px; height: auto; margin-top: 52px"
          src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c152dc9d-8765-4740-8288-5842fdb9d406.png"
          fit="contain"
        ></el-image>

        <div class="flex-item">
          <div class="item" style="margin-left: 20px">
            <div class="item-title">AI检测</div>
            <div class="item-detail">快速智能检测皮肤状况</div>
          </div>
          <div class="item" style="margin-left: 10px">
            <div class="item-title">测肤报告</div>
            <div class="item-detail">获取专业肤质检测报告</div>
          </div>
          <div class="item" style="margin-left: 10px">
            <div class="item-title">智能推荐</div>
            <div class="item-detail">1V1智能推荐美丽方案</div>
          </div>
          <div class="item" style="margin-right: 20px">
            <div class="item-title">肤质档案</div>
            <div class="item-detail">可视化数据记录肤质变化</div>
          </div>
        </div>
      </div>
    </div>
    <div class="skind-box" style="padding-bottom: 120px">
      <div class="container flexbox">
        <div class="title">强大后台，为美业连锁门店量身定制</div>
        <div class="detail">沉淀客户大数据，为美业连锁经营赋能</div>
        <div class="flex-box" style="margin-top: 40px">
           <div style="width: 21vw;">
            <el-image
            style="width: 100%; height: auto; margin-top: 40px"
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a110c30b-a5a4-4fb6-b225-a5240bcf14a0.png"
            fit="contain"
          ></el-image>
           </div>
          <div class="Tailoring-box">
            <div class="Tailoring-box-item" v-for="item in TailoringList">
              <div class="item-title" >
                {{ item.title }}
              </div>
              <div class="item-detail" >
                {{ item.detail }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skind-boxd">
      <div class="container flexbox">
        <div class="title">合作伙伴成功案例</div>
        <div class="detail">洞察科技赋能品牌商，创新引领业务增长</div>
        <div class="carousel-box">
          <!-- 轮播组件 -->
          <Carousel :list="CarouselList"></Carousel>
        </div>
      </div>
    </div>
    <div class="skind-box" style="padding-bottom: 120px">
      <div class="container flexbox">
        <div class="title">更多AI应用</div>
        <div class="detail">科技加码创新力</div>
        <div class="flex-box">
          <div class="AI-boxitem" v-for="item in AIbox" :style="{'background-image':`url(${item.bgimg})`}">
            <div class="AI-boxitem-title">{{item.title}}</div>
            <div class="AI-boxitem-detail">
              {{ item.detail }}
            </div>
            <div class="AI-boxitem-btn">了解详情 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";
export default {
  name: "OfficialMeiyewebSkindetection",
  components: {
    Carousel,
  },
  data() {
    return {
      TailoringList: [
        {
          title: "01 适应连锁品牌管理",
          detail:
            "支持创建单店或者多点运营，主账户管理子账户，统一管理产品推荐、参数配置、报告显示、统计分析等",
        },
        {
          title: "02 客户数据规范化",
          detail:
            "支持建立以用户为中心的健康档案，沉淀用户画像，运营后台可全盘查看数据，对客户数据深度挖掘，让数据产生价值",
        },
        {
          title: "03 品牌统一管理",
          detail:
            "主账户统一配置全部子账户小程序端logo更换，统一管控连锁品牌门店形象",
        },
        {
          title: "04 支持产品推荐方案录入",
          detail:
            "根据用户检测结果，可智能推荐适用的产品或服务，推荐的商品及护理建议可由商家自行录入系统。支持单品、多品种上传方式，实现高效营销",
        },
        {
          title: "05 支持公域私域商城对接",
          detail:
            "打造品牌专属的AI测肤商城，可跳转自营小程序商城、京东商城等，有效承接公域流量，盘活私域流量",
        },
        {
          title: "06 支持会员体系打造",
          detail:
            "融入会员体系，小程序端支持设置品牌会员免费检测，非会员付费体验，会员查看历史肤质变化记录等功能，增强会员粘性",
        },
      ],
      CarouselList: [
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/fa6a5f79-9a8b-49b1-ae95-e56235664dc1.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/581e9071-cdaa-4084-911e-6e21c205dbff.png",
          title: "觅光",
          detail:
         "中国居家美容科技领军品牌,AMIRO觅光，从科技美护行业新人成长为美容仪赛道当之无愧的“国货之光”，AMIRO觅光的崛起密码与其不断加码科研创新密不可分。",
            url:'https://mp.weixin.qq.com/s/kJgAP9yexTJikZjJ_2MaKQ'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c96ffc54-51cf-430f-b635-bb49a5399a8c.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a0dd9142-0076-4306-b567-31cb3d4fe24d.png",
          title: "",
          detail:
          "深耕皮肤领域30年，核心产品“地奈德乳膏”、“萘替芬酮康唑乳膏”、“他克莫司软膏”、“盐酸左西替利嗪”等在相关品类中处于领先地位，累计取得发明专利授权170余项",
            url:'https://mp.weixin.qq.com/s/7sDBB_y_xb8kv5ymLfYTiQ'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/e7514c1a-1e0b-4f7b-825e-e233dafab3ff.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/95e67a9c-121a-4297-b482-58468abc747b.png",
          title: "",
          detail:
          "电子烟产品研发企业，为悦刻等知名电子烟品牌提供专业的ODM服务， 公司依托核心的“雾化”技术，创新应用于跨界产品—— 一款创新型美容仪，通过高精度雾化技术，为用户提供定制化的护肤体验。",
            url:'https://www.xinpianchang.com/a12338661?kw=raresee&from=search_post'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/cf5b5b01-9114-4e58-bd32-6bac7efc0a95.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/6bb2e602-88de-4148-aebf-4884964a5a0c.png",
          title: "",
          detail:
          "专业从事OEM/ODM护肤品研发设计,集研发、生产、销售于一体，研发 生产基地占地60亩，采用先进的3.0智能工厂模式，内设研究院、CNAS认证检测中心，配备了十万级GMP无尘净化药用级车间，旗下拥有2大自主品牌：AI量肤定制新锐品牌[宣季]、高性价比护肤品牌[蜜都]。",
            url:'https://mp.weixin.qq.com/mp/homepage?__biz=MzA4NzY3NDQ3OQ==&hid=3&sn=730f05b04319b8229be8ddd0a9319ab5&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6309092b&lang=zh_CN&ascene=0'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/4f56860f-ff44-41a8-b2dd-bec6f702f2cf.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/ff9ebb6e-60e5-4ba5-9f93-f5a3f6d23b24.png",
          title: "",
          detail:
          "广东省十大化妆品代理企业，代理了包括珀莱雅、丸美、卡姿兰、百雀羚 等国内外二十多个知名品牌，全国拥有200家连锁店。旗下清洁、泥膜、睡膜产品占据了清洁品类行业的35%份额，发展势头强劲。",
            url:'https://mp.weixin.qq.com/s?__biz=MzA4NzY3NDQ3OQ==&mid=2247500341&idx=1&sn=04d243b2416d1372e9e233e9436a60f7&scene=19#wechat_redirect'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/3035f02d-29d5-4882-a4f6-d1948444851f.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/1b992f14-f920-47bf-a09a-d094ee6b3ed2.png",
          title: "",
          detail:
          "订阅式美妆赛道新锐品牌，依托韩国美妆产品的优势地位，创新 推出“订阅惊喜盒子”模式，玩转海内外营销与销售。",
            url:'https://mp.weixin.qq.com/s?__biz=MzA4NzY3NDQ3OQ==&mid=2247500643&idx=1&sn=2abd066f02d2fa4606b4f2a7a3f4a2ea&scene=19#wechat_redirect'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/af9c1e95-9fac-4f5c-ac0f-46d57dbdbb23.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/46c4e4ab-efd0-4638-af3b-fbc75c86fb59.png",
          title: "",
          detail:
          "位于佛山的知名网红化妆品制造工厂，一家经验丰富的OEM服务 提供商，提供包括配方研发、包装设计、生产制造在内的全方位一站式解决方案。在全国设有20多个运营中心，其品牌直营及加盟连锁门店数量超过2000家，遍布各地。",
            url:'https://mp.weixin.qq.com/s?__biz=MzA4NzY3NDQ3OQ==&mid=2247498527&idx=1&sn=7306881337d059980219b7d245c38c1e&chksm=9037601fa740e909fd199f5e255d6f74dd852ee5dd1e318be0469a2d6e1404cc2a1bc723db46&scene=21#wechat_redirect'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/de9c264b-a075-4807-829a-c93b9d41fed4.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/5c2e733a-5ddc-4d3c-9495-601a565e47a2.png",
          title: "",
          detail:
          "中国最大的LED植物灯 、LED光疗灯解决方案提供商，产品 覆盖欧美 、中东、日韩、东南亚等60多个国家和地区，旗下的SGROW品牌获得全球广大用户的好评和认可。",
            url:'https://mp.weixin.qq.com/mp/homepage?__biz=MzA4NzY3NDQ3OQ==&hid=3&sn=730f05b04319b8229be8ddd0a9319ab5&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6309092b&lang=zh_CN&ascene=0'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/1d4c4988-8f7f-4c3c-a29f-7b697236c64a.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/d143b585-283f-4d3d-aa44-c09038eea744.png",
          title: "",
          detail:
          "专业日光浴机、美黑机、红光美容机制造商，产品线涵盖智能穿戴设备、 健康监测仪器、健康管理系统等多个领域，为全球17000名企业客户及超过1000万名消费者提供了专业化、定制化体育健康美容产品和服务。产品远销欧美、澳洲、中东、亚太等100多个国家和地区，成为横跨美容、健康、医疗、体育四大产业的综合性跨行业的多元化综合体。",
            url:'https://mp.weixin.qq.com/mp/homepage?__biz=MzA4NzY3NDQ3OQ==&hid=3&sn=730f05b04319b8229be8ddd0a9319ab5&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6309092b&lang=zh_CN&ascene=0'
        },
     ],
     AIbox: [
       {
       bgimg: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/83f01973-ff69-49c0-9c71-0c37581bccf2.png',
       title: 'AI体质检测',
       detail:'智能中医问诊，AI实现望闻问切，赋能中医馆、中小诊所等领域'
      },
      {
       bgimg: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b0aa48a8-9821-46e8-bdb4-ff37ff2e9f4f.png',
       title: 'AI体态检测',
       detail:'通过人体关键节点检测智能分析体态，广泛应用于康复中心、理疗馆等领域'
       }
      ]
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.skind-box {
  background: #f9fafc;
  width: 100%;
  padding: 94px 0px 51px 0px;
}
.skind-boxd {
  background: #fff;
  width: 100%;
  padding: 77px 0px 115px 0px;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 17px;
}
.detail {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.flex-item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 60px;
}
.item {
  text-align: center;
}
.item-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #04cb94;
}
.item-detail {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  margin-top: 11px;
}
.Tailoring-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 100px;

  &-item {
    width: 330px;
    height: 155px;
    background: #ffffff;
    box-shadow: 4px 4px 27px 0px rgba(4, 203, 148, 0.09);
    border-radius: 10px;
    padding: 29px 26px 30px 24px;
    box-sizing: border-box;
    margin-right: 16px;
    margin-top: 23px;
  }
}
.carousel-box {
  width: 1100px;
  height: 424px;
  margin-top: 44px;
}
.AI-boxitem {
  width: 689px;
  height: 320px;
  padding: 80px 0px 117px 98px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &-title {
    font-size: 24px;
    font-family: MiSans;
    font-weight: bold;
    color: #333333;
  }
  &-detail {
    width: 250px;
    height: 40px;
    font-size: 14px;
    font-family: MiSans;
    font-weight: 300;
    color: #666666;
    line-height: 25px;
    margin: 29px 0px;
  }
  &-btn {
    font-size: 14px;
    font-family: MiSans;
    font-weight: 400;
    color: #04cb94;
  }
}
</style>
