<template>
  <div class="AboutUs">
    <!-- 头部 -->
    <common-nav @clickBtn="toFooterWrap" />
    <!-- 广告 -->
    <custom-banner
      :data="bannerData"
      :mode="2"
      layout="left"
      theme="#04CB94"
      text_color="black"
      :border-radius="50"
      :is-cns="isCns"
      @clickBtn="toFooterWrap"
    ></custom-banner>
    <!-- 中间内容部分 -->
    <div class="AboutUs-content">
      <div class="container">
        <div class="about-flex">
          <div>
            <div class="AboutUs-title">{{ $t('AboutUs.TrendMeiye') }}</div>
            <div class="AboutUs-details">
              {{ $t('AboutUs.TrendBeautyprovides') }}</br>
              <div style="margin-top: 40px">
                {{ $t('AboutUs.wehaveaccumulated') }}
              </div>
            </div>
          </div>
          <img
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c6b942da-a4ab-4b68-8009-e67d521b39fd.png"
            alt=""
            class="AboutUs-image"
          />
        </div>
        <div class="about-flex about-space-around" style="margin-top: 138px">
          <div class="AboutUs-tabs">
            <div>5000+</div>
            <div :style="{'font-size':$currentLanguage=='en'?'16px':''}">{{$t('AboutUs.GlobalCustomers')}}</div>
          </div>
          <div class="AboutUs-tabs">
            <div>35+↑</div>
            <div :style="{'font-size':$currentLanguage=='en'?'16px':''}">{{$t('AboutUs.managementefficiency')}}</div>
          </div>
          <div class="AboutUs-tabs">
            <div>30+↑</div>
            <div :style="{'font-size':$currentLanguage=='en'?'16px':''}">{{$t('AboutUs.acquisitionefficiency')}}</div>
          </div>
          <div class="AboutUs-tabs">
            <div>25+↑</div>
            <div :style="{'font-size':$currentLanguage=='en'?'16px':''}">{{$t('AboutUs.operationalefficiency')}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 领航团队 -->
    <div class="Navigation">
      <div class="container">
        <div class="Navigation-title about-flex about-space-around">
          {{$t('AboutUs.Navigationteam')}}
        </div>
        <div class="Navigation-box about-flex about-space-around">
          <div
            class="Navigation-item about-flex space-around about-flex-direction"
          >
            <img
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a382d335-d90d-4e12-8b91-a793e1dbe113.png"
              alt=""
            />
            <div class="Navigation-item-name">{{$t('AboutUs.HuangLiangxing')}}</div>
            <div class="Navigation-item-detail">
              <p>{{$t('AboutUs.GeneralManager')}}</p>
              <p>{{$t('AboutUs.ListedCompanies')}}</p>
              <p>{{$t('AboutUs.experienceoverdue')}}</p>
              <p>{{$t('AboutUs.DirectorofOperations')}}</p>
            </div>
          </div>
          <div
            class="Navigation-item about-flex space-around about-flex-direction"
          >
            <img
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/1f1045a5-b3d0-4ac4-971b-0f70a0006fa4.png"
              alt=""
            />
            <div class="Navigation-item-name">Maggie Leung</div>
            <div class="Navigation-item-detail">
              <p>{{$t('AboutUs.BusinessSchool')}}</p>
              <p>{{$t('AboutUs.Chairman')}}</p>
              <p>{{$t('AboutUs.Senior')}}</p>
              <p>{{$t('AboutUs.BeautyIndustry')}}</p>
            </div>
          </div>
          <div
            class="Navigation-item about-flex space-around about-flex-direction"
          >
            <img
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e7581ad5-35a2-4256-b733-8ba2e6cf35b0.png"
              alt=""
            />
            <div class="Navigation-item-name">{{$t('AboutUs.JiaChao')}}</div>
            <div class="Navigation-item-detail">
              <p>{{$t('AboutUs.ChiefTraditional')}}</p>
              <p>{{$t('AboutUs.Academic')}}</p>
              <p>{{$t('AboutUs.Professor')}}</p>
              <p>{{$t('AboutUs.Technical')}}</p>
            </div>
          </div>
          <div
            class="Navigation-item about-flex space-around about-flex-direction"
          >
            <img
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a488ff12-c6c2-4262-aa65-60bf642f401c.png"
              alt=""
            />
            <div class="Navigation-item-name">{{$t('AboutUs.WenGuihua')}}</div>
            <div class="Navigation-item-detail">
              <p>{{$t('AboutUs.Technology')}}</p>
              <p>{{$t('AboutUs.Director')}}</p>
              <p>{{$t('AboutUs.Leader')}}</p>
              <p>{{$t('AboutUs.Member')}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 探索历程 -->
    <div class="exploration">
      <div class="container">
        <div class="Navigation-title about-flex about-space-around">
          {{$t('AboutUs.explorationprocess')}}
        </div>
        <div class="about-flex">
          <img
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/519c3c6e-2f27-4c33-a95f-23bdd02b81c1.png"
            alt=""
            class="exploration-arrow"
            @click="changecarous(0)"
          />
          <el-carousel
            :interval="3000"
            arrow="never"
            indicator-position="outside"
            ref="carousel"
            :autoplay="false"
          >
            <el-carousel-item v-for="(item, index) in courseList" :key="index" >
              <div class="about-flex about-space-around">
                <div
                  class="exploration-item about-flex about-flex-direction"
                  v-for="it in item.children"
                >
                  <div>{{ it.title }}</div>
                  <div style="text-align: center" :style="{'font-size':$currentLanguage=='en'?'12px':'','width':$currentLanguage=='en'?'100%':''}">{{ it.content }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <img
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8f86d1b8-165a-427a-9437-611d434477ce.png"
            alt=""
            class="exploration-arrow"
            @click="changecarous(1)"
          />
        </div>
        <div class="about-flex about-space-around" style="margin-top: 12px">
          <div class="about-flex">
             <div class="about-flex space-around about-flex-direction align-center" v-for="(item,index) in tansuolist">
                   <img :src="item.img" alt="" class="exploration-img" :style="{'opacity':item.blockefd}"/>
                   <img :src="item.img1" alt="" class="exploration-img1" style="margin: 10px 10px;" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)"/>
             </div>
          </div>
        </div>
        <div class="exploration-btn">{{$t('AboutUs.Embark')}}</div>
      </div>
    </div>
    <div class="ContactUs">
      <div class="container">
        <div class="Navigation-title about-flex about-space-around">{{$t('AboutUs.ContactUs')}}</div>
        <div class="about-flex about-space-around" style="margin-top: 100px;">
            <div class="ContactUs-item about-flex about-space-around about-flex-direction">
               <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/576531b6-9451-4065-921e-fde3ee26e45e.png" alt=""/>
               <div class="ContactUs-item-name">{{$t('AboutUs.Guangzhou')}}</div>
               <div class="ContactUs-item-detail" :style="{'font-size':$currentLanguage=='en'?'12px':''}">
                <p>{{$t('AboutUs.ZhongshanAvenue')}}</p>
                <p>{{$t('AboutUs.WestLadder')}}</p>
               </div>
            </div>
            <div class="ContactUs-item about-flex about-space-around about-flex-direction">
               <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/100a354c-086d-4073-abbc-57cd813c2e96.png" alt=""/>
               <div class="ContactUs-item-name">{{$t('AboutUs.HongKong')}}</div>
               <div class="ContactUs-item-detail" :style="{'font-size':$currentLanguage=='en'?'12px':''}">UNIT1-31/FSTERLING CENTRE11 CHEUNG YUE STREET.LAI CHI KOK,KLN HONG KONG</div>
            </div>
            <div class="ContactUs-item about-flex about-space-around about-flex-direction">
               <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6d86afbe-e955-4c47-b9b7-96615fe644ff.png" alt=""/>
               <div class="ContactUs-item-name">{{$t('AboutUs.Foshan')}}</div>
               <div class="ContactUs-item-detail" :style="{'font-size':$currentLanguage=='en'?'12px':''}">
                <p>{{$t('AboutUs.FenjiangMiddle')}}</p>
                <p>{{$t('AboutUs.Room')}}</p>
               </div>
            </div>
        </div>
        <div class="about-flex about-space-around" style="margin-top: 125px;">
           <div class="ContactUs-box about-flex">
              <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f1bf739c-df5c-4024-bd05-8ea52c7882c6.png" alt="">
               <div class="ContactUs-box-phone">
                  <div >{{$t('AboutUs.phone')}}</div>
                  <div style="margin-top: 18px;">180-2733-5832</div>
               </div>
           </div>
           <div class="ContactUs-box about-flex">
              <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ada77a3a-b74a-41f4-8b04-08eef0386f34.png" alt="">
               <div class="ContactUs-box-emial">
                  <div >{{$t('AboutUs.mailbox')}}</div>
                  <div style="margin-top: 18px;">qushiyunscb@ecmob.cn</div>
               </div>
           </div>
        </div>
      </div>
    </div>

    <!-- 回到顶部 -->
    <backTOP />
    
    <!-- 页脚 -->
    <div id="footer">
      <custom-footer />
    </div>
  </div>
</template>

<script>
// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue";
// 广告图组件
import customBanner from "@/components/customBanner/index.vue";
// 页脚
import customFooter from "@/components/customFooter/index.vue";
export default {
  name: "OfficialMeiyewebIndex",
  components: {
    commonNav,
    customBanner,
    customFooter,
  },
  data() {
      return {
      isCns: false,
      bannerData: [
        {
          title: this.$t("footer.linkTitle4_label2"),
          // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
          banner:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/4469bf73-904c-4cb0-878f-7880c496b0ea.png",
          subtitle: this.$t("AboutUs.Facingthe"),
          content: [this.$t("AboutUs.Provideaone-stop")],
          button: this.$t("AboutUs.Freeexperience"),
        },
      ],
      courseList: [
        {
          title: "第一页",
          children: [
            {
              title: "2005",
              content: this.$t('AboutUs.foundedTrend'),
            },
            {
              title: "2006",
              content: this.$t('AboutUs.Awarded'),
            },
            {
              title: "2011",
              content:
                this.$t('AboutUs.Thesupplychain'),
            },
            {
              title: "2018",
              content:
                this.$t('AboutUs.Unitedwith'),
            },
          ],
        },
        {
          title: "第一页",
          children: [
            {
              title: "2020",
              content:
                this.$t('AboutUs.SouthChina'),
            },
            {
              title: "2021",
              content:
                this.$t('AboutUs.TheArtificialIntelligence'),
            },
            {
              title: "2022",
              content:
               this.$t('AboutUs.TrendBeauty'),
            },
            {
              title: "2023",
              content:
                this.$t('AboutUs.TrendCloud'),
            },
          ],
        },
        ],
        tansuolist: [
          {
            img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f65c1f36-8c81-455a-8289-15a38857d885.png',
            img1: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/d760fcaf-caef-47ab-8fec-61a7f24d53db.png',
            blockefd:'0'
          },
          {
            img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f65c1f36-8c81-455a-8289-15a38857d885.png',
            img1: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e5aae8ae-d7f4-48d3-83bb-a1ec0f68748e.png',
            blockefd:'0'
          },
          {
            img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/081d62cb-be22-4003-8485-c2b0fc18d090.png',
            img1: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/81d5fada-8940-45e4-bcb4-85d6b3dd4157.png',
            blockefd:'0'
          },
          {
            img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/4d06f1ac-f23f-4aac-a9b5-47ed93265851.png',
            img1: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/81d5fada-8940-45e4-bcb4-85d6b3dd4157.png',
            blockefd:'0',
          },
          {
            img: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6ec66ffa-8723-45cf-a279-fdb718fa962e.png',
            img1: 'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/736d3ac1-e228-40d4-8b71-e9e85634e2d9.png',
            blockefd:'0',
          }
      ]
    };
  },

  mounted() {},

  methods: {
    /**
     * 锚点定位
     */
    toFooterWrap() {
      document.querySelector("#footer").scrollIntoView({
        behavior: "smooth",
      });
    },
    changecarous(index) {
      this.$nextTick(() => {
        if (index == 0) {
          this.$refs.carousel.prev();
        } else {
          this.$refs.carousel.next();
        }
      });
    },
    mouseenter(index) {
      this.tansuolist[index].blockefd = '1'
    },
    mouseleave(index) {
      this.tansuolist[index].blockefd = '0'
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .exploration .el-carousel {
  flex: 1;
  height: 280px;
}
/deep/ .exploration .el-carousel__container {
  height: 240px;
}
.align-center{
  align-items: center;
  
}
/deep/ .el-carousel__button {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #2fc1a0;
}
.AboutUs-content {
  width: 100%;
  background-color: #f9fafc;
//   height: 812px;
  padding: 111px 0px 139px 0px;
  box-sizing: border-box;
}
.AboutUs-title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.AboutUs-details {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 58px;
}
.AboutUs-image {
  width: 474px;
  height: 318px;
  margin: 30px 0px 0px 25px;
}
.AboutUs-tabs {
  div {
    font-family: Microsoft YaHei;
    &:nth-child(1) {
      font-size: 62px;
      font-weight: bold;
      color: #24c9a5;
    }
    &:nth-child(2) {
      font-size: 22px;
      font-weight: 400;
      color: #666666;
    }
  }
}
.Navigation {
  width: 100%;
  background-color: #fff;
//   height: 720px;
  padding: 89px 0px 165px 0px;
  box-sizing: border-box;
}
.Navigation-title {
  font-size: 42px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.Navigation-box {
  margin-top: 108px;
}
.Navigation-item {
  img {
    width: 130px;
    height: 130px;
  }
  div {
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
}
.Navigation-item-name {
  font-size: 30px;
  color: #333333;
  margin: 31px 0px 29px 0px;
}
.Navigation-item-detail {
  width: 300px;
  font-size: 14px;
  color: #666666;
  line-height: 27px;
  text-align: center;
}
.exploration {
  width: 100%;
  background-color: #f9fafc;
//   height: 761px;
  padding: 76px 0px 67px 0px;
  box-sizing: border-box;
}
.exploration-item {
  width: 249px;
  height: 169px;
  background: rgba(47, 193, 160, 0.1);
  border: 1px solid rgba(47, 193, 160, 0.55);
  box-shadow: 4px 6px 18px 0px rgba(203, 197, 197, 0.26);
  border-radius: 14px;
  padding: 20px 0px 32px 0px;
  box-sizing: border-box;
  margin-top: 65px;
  div {
    font-family: Microsoft YaHei;
    &:nth-child(1) {
      font-size: 22px;
      font-weight: bold;
      color: #2fc1a0;
    }
    &:nth-child(2) {
      font-size: 15px;
      width: 166px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin-top: 10px;
    }
  }
}
.exploration-arrow {
  width: 19px;
  height: 24px;
  cursor: pointer;
}
.exploration-img {
  margin-left: 5px;
}
.exploration-img1 {
  margin-right: 54px;
  cursor: pointer;
}
.exploration-btn {
  width: 179px;
  height: 51px;
  background: #2fc1a0;
  border-radius: 0px 16px 0px 19px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f9fafc;
  margin-top: 67px;
  text-align: center;
  line-height: 51px;
  margin: 67px auto;
}
.ContactUs{
    width: 100%;
    // height: 850px;
    background-color: #fff;
    padding: 90px 0px 91px 0px;
    box-sizing: border-box;
}
.ContactUs-item-name{
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin: 18px 0px 29px 0px;
}
.ContactUs-item-detail{
    width: 332px;
    height: 41px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 27px;
    text-align: center;
}
.ContactUs-box{
    width: 468px;
    height: 132px;
    background: #FFFFFF;
    box-shadow: 0px 4px 18px 0px rgba(0,0,0,0.1);
    border-radius: 66px;    
    padding: 22px 0px 22px 34px;
    box-sizing: border-box;
}
.ContactUs-box-phone{
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2FC1A0;
    margin-left: 34px;
}
.ContactUs-box-emial{
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #26B2F0;
    margin-left: 34px;
}
</style>
