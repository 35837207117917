import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';

// 回到顶部
import backTOP from "@/components/backTOP/index.vue"

// 引入Axios
import axios from 'axios';
// 将axios挂载到Vue原型上
Vue.prototype.$http = axios;
// 存储国际化语言
Vue.prototype.$currentLanguage = localStorage.getItem('currentLang') || process.env.VUE_APP_DEFAULT_LANG || 'zh-cn'

// 国际化
import i18n from "@/lang/index";

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.component('backTOP', backTOP);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  console.log(to.meta.content)
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.content&&to.meta.content.title) {
    document.title = to.meta.content.title;
  }
  next()
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
