<template>
  <div class="Miniprogram">
    <!-- 头部 -->
    <common-nav @clickBtn="toFooterWrap" />
    <!-- 广告 -->
    <custom-banner
      :data="bannerData"
      :mode="2"
      layout="left"
      theme="#04CB94"
      text_color="black"
      :border-radius="50"
      :is-cns="isCns"
      @clickBtn="toFooterWrap"
    ></custom-banner>
    <!-- 美业小程序下面的box -->
    <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox">
        <div class="title">{{$t('MeiyeChain.box1_title')}}</div>
        <div class="desc">{{$t('MeiyeChain.box1_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
           {{$t('MeiyeChain.box1_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6c5e4a34-fd8f-419f-a117-cad023a1a272.png" alt="" class="img1">
      </div>
    </div>
    </div>
    <div class="Miniprogram-box container">
         <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/82cab595-02e4-4933-8914-e147061ee0cc.png" alt="" class="img2">
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MeiyeChain.box2_title')}}</div>
        <div class="desc">{{$t('MeiyeChain.box2_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MeiyeChain.box2_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
    <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox">
        <div class="title">{{$t('MeiyeChain.box3_title')}}</div>
        <div class="desc">{{$t('MeiyeChain.box3_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MeiyeChain.box3_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/cae390d7-c2cd-4916-8fb4-aa248856ade5.png" alt="" class="img3">
      </div>
    </div>
    </div>
     <div class="Miniprogram-box container ">
         <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e23e5d9f-381d-4b92-bc7d-e5a709d52943.png" alt="" class="img4">
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MeiyeChain.box4_title')}}</div>
        <div class="desc">{{$t('MeiyeChain.box4_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MeiyeChain.box4_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
    <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox" style="height:620px">
        <div class="title">{{$t('MeiyeChain.box5_title')}}</div>
        <div class="desc">{{$t('MeiyeChain.box5_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
         {{$t('MeiyeChain.box5_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/bfbb453f-d37c-4e93-8a09-c49982f2b613.png" alt="" class="img5">
      </div>
    </div>
    </div>
    <div class="Miniprogram-box container ">
         <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/28195087-c6d6-4067-bd51-8a4f74208600.png" alt="" class="img6">
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MeiyeChain.box6_title')}}</div>
        <div class="desc">{{$t('MeiyeChain.box6_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MeiyeChain.box6_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>

    <!-- 回到顶部 -->
    <backTOP />

    <!-- 页脚 -->
    <div id="footer">
      <custom-footer />
    </div>
  </div>
</template>

<script>
// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue";
// 广告图组件
import customBanner from "@/components/customBanner/index.vue";
// 页脚
import customFooter from "@/components/customFooter/index.vue";
export default {
  name: "OfficialMeiyewebIndex",
  components: {
    commonNav,
    customBanner,
    customFooter,
  },
  data() {
    return {
      isCns: false,
      bannerData: [
        {
          title: this.$t('nav.beautyChain'),
          // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
          banner:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/cc5bf315-d12a-47fd-8932-d276b175c379.png",
          subtitle: this.$t('MeiyeChain.bancontent1'),
          content: [
            this.$t('MeiyeChain.bancontent2'),
          ],
          button: this.$t("home.bannerData_button1"),
        },
        // {
        //   title: this.$t("home.bannerData_title2"),
        //   // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
        //   banner:
        //     "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f5a5e209-faa4-483b-9fea-72b1c393dc22.png",
        //   subtitle: this.$t("home.bannerData_subtitle2"),
        //   content: [],
        //   button: this.$t("home.bannerData_button2"),
        // },
      ],
    };
  },

  mounted() {},

  methods: {
    /**
      * 锚点定位
      */
    toFooterWrap() {
      document.querySelector('#footer').scrollIntoView({
				behavior: "smooth"
		  });
    },
  },
};
</script>

<style lang="less" scoped>
.minbox{
    width: 100%;
    background-color: #F9FAFC;
}
.minleft{
    margin-left: 151px;
}
.Miniprogram {
  &-box {
    // height: 651px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;
    &-contentbox {
      width: 782px;
      height: 555px;
      display: flex;
      padding-top: 120px;
      flex-direction: column;
      .title {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .desc {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 11px;
      }
      .heng {
        width: 60px;
        height: 6px;
        background: #04cb94;
        margin: 24px 0px 29px 0px;
      }
      .content {
        width: 512px;
       
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
      .btns {
        width: 111px;
        height: 43px;
        background: #04cb94;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 43px;
        margin-top: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
    &-pontimg{
        .img1{
            width: 744px;
            height: 302px;
        }
        .img2{
            width: 586px;
            height: 532px;
        }
        .img3{
            width: 480px;
            height: 464px;
        }
        .img4{
            width: 698px;
            height: 486px;
        }
        .img5{
            width: 526px;
            height: 532px;
        }
        .img6{
            width: 636px;
            height: 444px;
        }
       
    }
  }
}
</style>
