<template>
  <div>
    <div class="skind-box" animated>
      <div class="container flexbox">
        <div class="title">趋势云中医体质辨识整体方案</div>
        <div class="detail">智能检测中医9种体质，精准推荐调理方案</div>
        <div class="tabs-box">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.label"
              :name="item.index"
              v-for="item in medicalscience"
              :key="item.label"
            >
              <div class="tabimg-item cs02">
                 <div class="tabimg">
                  <el-image
                  style="width: 100%; height: auto"
                  :src="item.img"
                  fit="contain"
                ></el-image>
                 </div>
                <div class="tabimg-item-rigbox">
                  <div class="rigbox-title">{{ item.title }}</div>
                  <div class="rigbox-detail">{{ item.detail }}</div>
                  <div class="rigbox-list">
                    <div
                      class="rigbox-list-item"
                      v-for="it in item.introduce"
                      :key="it"
                    >
                      {{ it }}
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="skind-boxd">
      <div class="container flexbox">
        <div class="title">AI核心算法</div>
        <div class="detail">
          广东省人工智能中医工程技术研究中心及华南理工大学倾力研发
        </div>
        <el-carousel
          indicator-position="outside"
          height="500px"
          style="width: 100%"
        >
          <el-carousel-item v-for="item in algorithmList" :key="item.title">
            <div class="algorithm">
              <div class="algorithm-box">
                <div class="algorithm-title">{{item.title}}</div>
                <div class="algorithm-cont">{{item.cont}}</div>
                <div class="algorithm-gan"></div>
                <div class="algorithm-detail">
                  {{item.detail}}
                </div>
              </div>
              <el-image
                style="width: 598px; height: 372px"
                :src="item.img"
                fit="contain"
              ></el-image>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="skind-box">
      <div class="container flexbox">
        <div class="title">多渠道灵活嵌入，按需配置</div>
        <div class="detail">小程序、APP、体质辨识屏、体质辨识机器人</div>
        <div class="channel-box">
          <div class="channel-box-item">
            <div class="channel-img">
             <el-image
              style="width: 100%; height: auto"
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/48d1d869-ae41-4402-9c6f-6618930698e6.png"
              fit="contain"
            ></el-image>
            </div>
            <div class="channel-box-title">手机移动端</div>
            <div class="channel-box-detail">
              通过API或H5接口等形式，为合作伙伴提供APP程序的舌诊、肤质检测线上对接服务
            </div>
          </div>
          <div class="channel-box-item">
           <div class="channel-img">
            <el-image
              style="width: 100%; height: auto"
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/166501f0-80f7-4563-9528-d0c72f979238.png"
              fit="contain"
            ></el-image>
           </div>

            <div class="channel-box-title">体质辨识智慧屏</div>
            <div class="channel-box-detail">
              体积更小更便携，自带身份证识别模块，通过望诊与问诊准确的对用户身体健康状况进行诊断
            </div>
          </div>
          <div class="channel-box-item">
           <div class="channel-img">
            <el-image
              style="width: 100%; height: auto"
              src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c8b32b6d-c406-4889-9266-b291e34b8f2f.png"
              fit="contain"
            ></el-image>
           </div>

            <div class="channel-box-title">智能中医机器人</div>
            <div class="channel-box-detail">
              实现中医四诊，望、闻、问、切，识别体质类型，展示体质类型及中医专家推荐的调理方案
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skind-boxd">
      <div class="container flexbox">
        <div class="title">合作伙伴成功案例</div>
        <div class="detail">洞察科技赋能品牌商，创新引领业务增长</div>
        <div class="carousel-box">
          <!-- 轮播组件 -->
          <Carousel :list="CarouselList"></Carousel>
        </div>
      </div>
    </div>
    <div class="skind-box">
      <div class="container flexbox">
        <div class="title">更多应用场景，满足多种需求</div>
        <div class="application-box">
          <div
            class="application-box-item"
            v-for="item in application"
            :key="item.title"
            :style="{ 'background-image': `url(${item.bgimg})` }"
          >
            <el-image
              style="width: 40px; height: 40px"
              :src="item.iconimg"
              fit="contain"
            ></el-image>
            <div class="application-title">{{ item.title }}</div>
            <div class="application-detail">{{ item.detail }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="skind-boxd">
      <div class="container flexbox">
        <div class="title">更多AI应用</div>
        <div class="detail">科技加码创新力</div>
        <div class="flex-box">
          <div
            class="AI-boxitem"
            v-for="item in AIbox"
            :style="{ 'background-image': `url(${item.bgimg})` }"
          >
            <div class="AI-boxitem-title">{{ item.title }}</div>
            <div class="AI-boxitem-detail">
              {{ item.detail }}
            </div>
            <div class="AI-boxitem-btn">了解详情 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";
export default {
  name: "OfficialMeiyewebPhysicalexamination",
  components: {
    Carousel,
  },
  data() {
    return {
      activeName: "1",
      medicalscience: [
        {
          label: "智能望诊",
          index: "1",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8ca6302d-b997-4a34-909b-85a4d45f1d07.png",
          title: "手机一拍，快速舌诊/面诊",
          detail:
            "通过摄像头，代替人眼，智能采集用户面部、舌像信息，一目了然，辩体知疾，为灵床诊疗提供辅助诊断依据。",
          introduce: ["快速舌诊", "智能采集", "辅助诊断"],
        },
        {
          label: "智能问诊",
          index: "2",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ed3eeea8-36e5-4adf-9f50-919e5b24d5b6.png",
          title: "数据分析后精准匹配问卷",
          detail:
            "基于采集到的用户基础数据，如健康情况、慢性病、用药信息等，结合系统内强大的中医辨证方法、中医知识图谱，智能匹配个性化问诊，准确的对用户身体健康状况进行诊断。",
          introduce: ["数据分析", "智能匹配", "健康诊断"],
        },
        {
          label: "体质报告",
          index: "3",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/33fdb106-ec06-47ee-b295-b9cb848c6695.png",
          title: "全面详实长达6页A4纸",
          detail:
            "用户可在个人中心查看详细的体质检测报告，如体质偏向性、兼有体质、舌象结果等，还有与检测报告相对应的系统智能推荐的解决方案+智能匹配推荐的商品，用户点击即可查看套餐详情或者跳转下单购买；",
          introduce: ["检测报告", "智能推荐", "智能匹配"],
        },
        {
          label: "精准推荐",
          index: "4",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6bb999dd-7c2e-4460-918b-9d4aa9127b7b.png",
          title: "个性化调养方式智能推荐",
          detail:
            "通过数据分析技术，对患者的病史、症状、体质等多维数据进行综合分析，为患者提供更加精准的中医治疗建议，制定相应的治疗方案，包括中药、针灸、推拿等。",
          introduce: ["综合分析", "治疗建议", "精准推荐"],
        },
        {
          label: "患者管理",
          index: "5",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8b66f410-5cd9-4c0e-985b-ce33153e9a87.png",
          title: "数字化精细化管理患者会员",
          detail:
            "建立患者档案，患者随时查看体质变化，在线咨询问诊，预约服务或者购买产品，提高用户粘性",
          introduce: ["患者管理", "在线咨询", "预约服务"],
        },
      ],
      CarouselList: [
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/26ea21cc-f9a7-4ec5-ac03-f5af5b7ee02a.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/f1fccf90-cdf7-4145-8865-d14b1833072c.png",
          title: "",
          detail:
         "成立于1994年的广州华美医疗美容医院，是亚洲最大的整形美容连锁机构之一， 中国5A级整形美容医院，总占地面积15000平方米。华美医院内设有医疗美容科、美容外科、美容牙科、美容皮肤科、美容中医科、医学检验科等科室，同时开展生活美容、中医美容、健康检测、心理疏导、美学应用等多项业务及科研致力于为顾客提供高品质的医美、生美服务。",
         url:'https://mp.weixin.qq.com/s?__biz=MzA4NzY3NDQ3OQ==&mid=2247501152&idx=1&sn=1a72096c3bb266f3f4753f228b7f26ab&scene=19#wechat_redirect'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/5e697787-a328-4847-b284-f3d2fe997ced.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/247f9a3c-6d7c-4de8-8fbb-be670d505a4c.png",
          title: "",
          detail:
         "1998 年创立至今，陈农夫已经是广州有名的药汤老品牌，一直以来以中医药膳为主打，结合自己研发的 65℃C酵温低温冷萃取技术设备，自研了55个系列，360 多种药膳汤。目前，陈农夫已经在全国各地拥有600+加盟店数是还在不断上升。",
         url:'https://mp.weixin.qq.com/s?__biz=MzA4NzY3NDQ3OQ==&mid=2247497875&idx=1&sn=38cf7be1a13fb80e4b9219edb7f9e93e&chksm=90376393a740ea851b9bda0f1f243cc418edc1b2e872e03286d2f57dc10c9f702a41629a412d&scene=21#wechat_redirect'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/e4142384-2047-4b87-abb3-901374fba219.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/172febfb-8346-49dc-b402-27359990eb11.png",
          title: "",
          detail:
         "新加坡知名中医诊所，由一位享誉业界、拥有逾三十年从医经验和临床 经验的老中医创立。诊所将传统中医与现代医学相结合，AI升级，提高诊疗效率。",
         url:'https://mp.weixin.qq.com/s?__biz=MzA4NzY3NDQ3OQ==&mid=2247501264&idx=1&sn=c2ad64de51d7929126f8204171481c1a&scene=19#wechat_redirect'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/3c726c57-6304-4378-ae5d-3223d7ebaffd.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/72495490-3df8-4b4b-89e5-80be39589c37.png",
          title: "奈瑞儿",
          detail:
         "成立于2007年的奈瑞儿，是专注于女性美丽健康调理的美容健康品牌，作为一家老牌美业连锁机构，十六年来深耕粤港澳大湾区，辐射全国9大城市，目前已开设200多家直营超级美养院，还与四川省中医药科学院合作成立了奈瑞儿女性亚健康研究中心，全方位满足顾客美丽和健康的需求。",
         url:'https://mp.weixin.qq.com/s/6jJkVavg2ZGxio3F0N-Ijg'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/31065966-bae1-41bf-a5a4-7f43890d63b6.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/449ed51b-abe5-463e-af10-7dfa7c93cea5.png",
          title: "",
          detail:
          "与超过200+家权威三甲医院、科研院所建立了广泛的科研合作，建成了首个中国人的健康与疾病免疫图谱数据库，包含30余种重大疾病的免疫特征数据，基于此建立了多个高发癌种的风险评估模型，能够帮助人们在疾病的早期甚至于疾病发生之前发现患病风险，从而介入健康管理与治疗的黄金时期。",
          url:'https://mp.weixin.qq.com/s/IF-sZfFfOgEKpeZUfx62Gw'
        },
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/03fe9185-f9f5-466e-b504-af2ee502fb5f.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/acb5f193-cbef-4ced-a7ac-0504eaf1507c.png",
          title: "",
          detail:
         "深耕大健康产业链，倡导“外调内养“核心理念，在华南与港澳地区共设立了14 家子公司与 180 多家线下直营门店，累计服务中老年客户超过 10 万。集团主营具有 40 年历史的中国家用医疗器械行业的领军品牌【周林频谱】，获殊荣;并通过了ISO9001、ISO13485 质量体系认证和欧盟CE认证。",
         url:'https://mp.weixin.qq.com/s/F2LnQg9qlSvXx1x-ibO7YA'
        },
      ],
      application: [
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/7297d5d4-34fc-4e47-8230-d8619a888049.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ecef1264-2804-4585-878b-d524e5e1646f.png",
          title: "医院",
          detail: "中医治病科室",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ecbe88a5-a285-463a-9e71-5c84f7067aef.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/23edd3bd-0a98-4b57-a63d-dcee548f3611.png",
          title: "药店",
          detail: "药品推荐个性化买药",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/493bd27d-bbb3-4039-a73b-65e03b861b68.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/cd5f64d0-3c47-4406-9136-c64d126fe2d0.png",
          title: "养老院",
          detail: "关爱老年人",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/91679cca-bf98-49de-82e4-0a03c15b76bb.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/84dc2a86-cf8c-4682-bcf9-b491c8a22683.png",
          title: "社区",
          detail: "营造健康社区",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/227cc9b5-b206-444f-902d-4e2f63c19c52.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/61f04442-413b-4f29-9127-804548c24676.png",
          title: "月子中心",
          detail: "关注母婴健康",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5a9ebaed-aee8-47a9-972c-a6e52e4b7001.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b4fffb22-2dce-47b5-bbec-dff5b88ce454.png",
          title: "旅游",
          detail: "医疗旅游区域",
        },
      ],
      AIbox: [
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/06e4ce2a-cdfe-4712-9999-0a0c2df4d8b2.png",
          title: "AI皮肤检测",
          detail:
            "精准皮肤无检测，30+维度肤质问题快速检测，适合化妆品电商、美容仪电商、皮肤管理机构、医美机构等场所",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/071d1324-1505-44ab-86dd-f594391c9631.png",
          title: "AI体态检测",
          detail:
            "通过人体关键节点检测智能分析体态，广泛应用于康复中心、理疗馆等领域",
        },
     ],
     algorithmList: [
        {
         title: '更快识别',
         cont: '识别快30秒',
         detail: '实现中医四诊，望、闻、问、切，快速识别体质类型，直接展示体质报告及对应的专家推荐调理方案',
         img:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/fe7ada0c-5ed5-4247-8068-da8dac9acc2f.png'
      },
      {
         title: '更准判断',
         cont: '精准率＞91.2%',
         detail: '专家测评，联合广东省人工智能中医工程中心组织多次名老中医专家背靠背测评，准确率＞91.2%',
         img:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/fd1613be-3b50-439d-980d-ccd0344fc1a1.png'
      },
      {
         title: '更全面强大',
         cont: '中医知识图谱',
         detail: '英国皇家科学院和工程院两院院士，华南理工大学机器学习与数据挖掘实验室主任，广东省人工智能中医工程技术研究中心高级研究员+16位老中医+15位博士共同打造的中医养生知识库',
         img:'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/588952f5-01b0-476e-b8a5-63c914d0e075.png'
        }
     ]
    };
  },

  mounted() {},

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.tabimg{
  width: 592px;
}
.channel-img{
 width: 300px;
}
/**添加切换动画 */
.cs02 {
  animation: fadeInDown 1s 0.02s ease backwards;

  /*解释：
* fadeInleft 是引用插件中的一个动画名称
* 1s         是点击时，这个动画整体使用的时间
* 0.02s      是动画刚开始显示需要的时间
* ease       是动画开始的方向
* backwards  有啥效果，鄙人目前还没看出来，百度查的说是“规定对象动画时间之外的状态”
*/
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 45px 4px;
}
/deep/ .el-carousel__button {
  height: 6px;
}
/deep/ .el-carousel__indicator.is-active button {
  background: #04cb94;
}
/deep/ .el-tabs__nav-scroll {
  padding-left: 320px;
}
/deep/ .el-tabs__item {
  line-height: 20px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
/deep/ .el-tabs__item.is-active {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2ac4a3;
}
/deep/ .el-tabs__active-bar {
  width: 134px !important;
  height: 4px;
  background: #3cd2ad;
  margin-left: -30px;
}
.skind-boxd {
  background: #fff;
  width: 100%;
  padding: 77px 0px 144px 0px;
}
.skind-box {
  background: #f9fafc;
  width: 100%;
  padding: 99px 0px 178px 0px;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 17px;
}
.detail {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.tabs-box {
  width: 100%;
  margin-top: 66px;
}
.tabimg-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 68px;
  &-rigbox {
    display: flex;
    flex-direction: column;
    margin-top: 86px;
  }
  .rigbox-title {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #2ac4a3;
  }
  .rigbox-detail {
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #393939;
    width: 487px;
    margin-top: 33px;
  }
  .rigbox-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    width: 422px;
    &-item {
      width: 187px;
      height: 54px;
      background: #24c9a5;
      border: 2px solid #2aca9e;
      border-radius: 27px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 16px;
      text-align: center;
      line-height: 50px;
      margin-right: 22px;
    }
  }
}
.algorithm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 62px;
}
.algorithm-box {
  margin-top: 93px;
}
.algorithm-title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.algorithm-cont {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin: 12px 0px 27px 0px;
}
.algorithm-gan {
  width: 47px;
  height: 6px;
  background: #28c6a4;
  margin-bottom: 22px;
}
.algorithm-detail {
  width: 448px;
  height: 53px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
}
.channel-box {
  margin-top: 53px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &-item {
    width: 357px;
    height: 396px;
    background: #ffffff;
    box-shadow: 4px 6px 18px 0px rgba(203, 197, 197, 0.26);
    border-radius: 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 26px;
    box-sizing: border-box;
    margin-left: 43px;
  }
}
.channel-box-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2d2d2d;
  margin: 35px 0px 27px 0px;
}
.channel-box-detail {
  width: 310px;
  height: 39px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.carousel-box {
  width: 1100px;
  height: 424px;
  margin-top: 44px;
}
.application-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 92px;

  &-item {
    width: 324px;
    height: 290px;
    margin-left: 40px;
    padding: 120px 0px 41px 26px;
    margin-bottom: 32px;
  }
}
.application-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin: 12px 0px;
}
.application-detail {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.AI-boxitem {
  width: 689px;
  height: 320px;
  padding: 71px 0px 93px 98px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &-title {
    font-size: 24px;
    font-family: MiSans;
    font-weight: bold;
    color: #333333;
  }
  &-detail {
    width: 250px;
    font-size: 14px;
    font-family: MiSans;
    font-weight: 300;
    color: #666666;
    line-height: 25px;
    margin: 29px 0px;
  }
  &-btn {
    font-size: 14px;
    font-family: MiSans;
    font-weight: 400;
    color: #04cb94;
  }
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
</style>
