import request from "@/utils/request.js";

// 获取文章分类列表
export function getArticleCategoryPage(query){
    return request.request({
        url: '/mallapi/articlecategory/page',
        method: 'get',
        params: query,
    })
}

// 文章列表
export function getArticlePage(query){
    return request.request({
        url: '/mallapi/articleinfo/page',
        method: 'get',
        params: query,
    })
}

// 获取文章详情
export function getArticleInfo(articleId){
    return request.request({
        url: `/mallapi/articleinfo/${articleId}`,
        method: 'get',
    })
}

// 根据id获取表单控件数据
export function getCustomByFormId(query){
    return request.request({
        url: '/mallapi/remarkGroup/one',
        method: 'get',
        params: query,
    })
}

// 提交根据id获取的表单数据
export function postFormDataById(obj){
    return request.request({
        url: '/mallapi/remarkGroup/activity',
        method: 'post',
        data: obj,
    })
}