<template>
  <div class="">
    <!-- 头部 -->
    <common-nav @clickBtn="toFooterWrap" />
    <!-- 广告 -->
    <custom-banner
      :data="bannerData"
      :mode="2"
      layout="left"
      theme="#04CB94"
      text_color="black"
      :border-radius="50"
      :is-cns="isCns"
      @clickBtn="btnsbanner"
    ></custom-banner>
    <!-- 浏览客户案例 -->
    <div class="CustomerStories">
      <div class="container">
        <div class="CustomerStories-title about-flex about-space-around">
          {{ $t("Customer.Browsecustomer") }}
        </div>
        <div class="CustomerStories-box">
          <div
            class="CustomerStories-box-item"
            v-for="item in customerlist"
            :key="item.content"
            @click="clickJump(item.url)"
          >
            <img :src="item.logo" alt="" class="imgs" />
            <div style="margin-left: 20px">
              <div class="CustomerStories-box-item-title">
                {{ $t("Customer.Meiye") }}
              </div>
              <div
                class="CustomerStories-box-item-content"
                :style="{ 'font-size': $currentLanguage == 'en' ? '12px' : '' }"
              >
                {{ item.content }}
              </div>
              <img :src="item.imgicon" alt="" />
            </div>
          </div>
        </div>
        <div class="CustomerStories-imgtitle">
          {{ $t("Customer.Theimagecomes") }}
        </div>
      </div>
    </div>
    
    <!-- 回到顶部 -->
    <backTOP />

    <!-- 页脚 -->
    <div id="footer">
      <custom-footer />
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :show-close="false"
    >
      <div class="dialog-box">
        <div class="dialog-close">
          <img
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f1c20295-e944-4dc7-829d-b0ee5bdbc5cc.png"
            alt=""
            @click="dialogVisible = false"
          />
        </div>
        <div class="dialog-content">
          做好营销和销售，扩大你的生意就现在，立即获取增长方案！
        </div>
        <div class="dialog-btn">扫码咨询</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue";
// 广告图组件
import customBanner from "@/components/customBanner/index.vue";
// 页脚
import customFooter from "@/components/customFooter/index.vue";
export default {
  name: "OfficialMeiyewebIndex",
  components: {
    commonNav,
    customBanner,
    customFooter,
  },
  data() {
    return {
      isCns: false,
      dialogVisible: false,
      bannerData: [
        {
          title: this.$t("Customer.Advanced"),
          banner:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/1e5d79e4-d4f8-4818-9324-8dc08a221780.png",
          subtitle: this.$t("Customer.businessgrowth"),
          content: "",
          button: this.$t("Customer.Scheme"),
        },
        {
          title: "",
          banner:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/eef2e37b-9308-4fef-bbac-7876ba902d32.png",
          subtitle: this.$t("Customer.Deepening"),
          content: [this.$t("Customer.Narielhas")],
          button: this.$t("Customer.completestory"),
          openUrl: "https://mp.weixin.qq.com/s/6jJkVavg2ZGxio3F0N-Ijg",
        },
      ],
      customerlist: [
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/15967a16-61ea-416c-94b1-e25459e94fac.png",
          content: this.$t("Customer.SeekingLight"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/2ea33267-6386-41ae-8400-bc5d7ad2b8ef.png",
          url: "https://mp.weixin.qq.com/s/kJgAP9yexTJikZjJ_2MaKQ",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/3bdbd450-14a0-4cbb-853a-d90e78331a71.png",
          content: this.$t("Customer.SunshineMedical"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/6a4638f5-2b3d-4329-9be5-19ddecde754e.png",
          url: "https://mp.weixin.qq.com/s/eel5NZ8GWk6bJMivzlI4mA",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b285455c-17bb-4014-9ee3-abad7b2e2cda.png",
          content: this.$t("Customer.HuabangPharmaceuticals"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/53c773e5-f809-4be8-9f28-a498ea5e8ec9.png",
          url: "https://mp.weixin.qq.com/s/7sDBB_y_xb8kv5ymLfYTiQ",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/adf24d7a-6624-47bb-81b9-e3be1a0aa481.png",
          content: this.$t("Customer.ShampooCat"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/0c29ac4f-a8b2-4810-a5a1-73c2a59fccca.png",
          url: "http://m.xilianmiao.cn/",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ef25ee23-84f0-4edb-bb89-a0733d96a36c.png",
          content: this.$t("Customer.Unlimited"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/542372b4-402a-48bf-866c-5905dcae2551.png",
          url: "https://mp.weixin.qq.com/s/xUv3gnMofLhRl2-4V5LL0A?poc_token=HJ5uQGWjW_w2csNP9pRxR--BL2phvVrWlGR5WkcR",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a01ba9e4-bcd5-4f66-91ad-7a4928f6efee.png",
          content: this.$t("Customer.Proting"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c0626dfc-f1f6-470d-8561-1425f1ad706f.png",
          url: "https://mp.weixin.qq.com/s/IF-sZfFfOgEKpeZUfx62Gw",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/78f11a2a-94e2-4819-814c-1f448094702e.png",
          content: this.$t("Customer.LaiheSibao"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/0ccb3f55-68ce-42a9-bbc3-d18ac0ffefda.png",
          url: "https://mp.weixin.qq.com/s/NdDyplB0ISejtyvZ_NUd_Q",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e0b842ac-e42b-4de7-b813-44a66b964823.png",
          content: this.$t("Customer.Zeran"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b2fb5bac-e3cd-479a-b58e-f444e24f42c3.png",
          url: "https://mp.weixin.qq.com/s/rwYa8fOotWodIoqe-AbDFw",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/4db0d4f2-c301-4cfb-8ad2-8873520f98c5.png",
          content: this.$t("Customer.Meilikang"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/1c645797-7f4d-46a8-8505-43a975c914f7.png",
          url: "https://mp.weixin.qq.com/mp/homepage?__biz=MzA4NzY3NDQ3OQ==&hid=3&sn=730f05b04319b8229be8ddd0a9319ab5&scene=18&uin=&key=&devicetype=Windows+10+x64&version=6309071d&lang=zh_CN&ascene=0",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/8bccbad4-2946-4dea-8bed-bad828d70a03.png",
          content: this.$t("Customer.RARESEE"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/640c9d05-79d3-45b6-a567-55c5311b9e02.png",
          url: "https://www.xinpianchang.com/a12338661?kw=raresee&from=search_post",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f9b3ed4c-3abb-4d91-9889-f01904a711fe.png",
          content: this.$t("Customer.ChenNongfu"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/1026bef3-39ea-431e-b135-b4519458fd7e.png",
          url: "https://mp.weixin.qq.com/s/Gl4EzUm6ZVUiNp_JH7vixA",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/4301e75d-1bd6-4c94-97a0-24122e46ddea.png",
          content: this.$t("Customer.OuTinger"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/f1a9d7ea-1a81-4f48-98ef-019563ff4b51.png",
          url: "https://mp.weixin.qq.com/s/-RxQabqbGMZ4f5q1QipTJA",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/9ce69a33-0e71-4d84-919a-d86096db19f1.png",
          content: this.$t("Customer.Fenle"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ce32fef1-65d2-4cf4-9143-8e35dde2bbec.png",
          url: "https://mp.weixin.qq.com/s/BmdyF7ckG5tQmTyC0CdvXQ",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/2d2c00cc-cd59-4a97-a8d7-9bcb05fdb745.png",
          content: this.$t("Customer.PineappleTree"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/269e0d43-683a-4bc1-ab12-86ba746c4b0e.png",
          url: "https://mp.weixin.qq.com/s/ZQMNeJP-MihZp93leMjB1g",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/6b0108c9-1f93-4e42-a171-13109aa421a9.png",
          content: this.$t("Customer.Youkeyhui"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/c28943fa-fbcb-45e9-97d1-0bd6dda52b50.png",
          url: "https://mp.weixin.qq.com/s/F2LnQg9qlSvXx1x-ibO7YA",
        },
        {
          logo: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/92e023c0-fc64-4a7b-85de-45e0472e6143.png",
          content: this.$t("Customer.Huamei"),
          imgicon:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/ed320231-0090-4699-af25-ec36c1674028.png",
          url: "https://mp.weixin.qq.com/s/gC1PRwnOXdGA7faE1fOJSA",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    /**
     * 锚点定位
     */
    toFooterWrap() {
      document.querySelector("#footer").scrollIntoView({
        behavior: "smooth",
      });
    },
    clickJump(url) {
      window.open(url);
    },
    btnsbanner() {
      console.log(111);
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  background: transparent !important;
  box-shadow: none !important;
}

.dialog-box {
  background-image: url("https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/38963e3b-96df-493d-8e33-8c15a2ad3959.png");
  background-size: 100% 100%;
  width: 1002px;
  height: 602px;
  position: relative;
}
.dialog-close {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 10px 20px 0px 0px;

  img {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
.dialog-content {
  position: absolute;
  top: 120px;
  right: 100px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 420px;
  height: 64px;
}
.dialog-btn {
  width: 200px;
  height: 60px;
  background: #000000;
  border-radius: 30px;
  position: absolute;
  cursor: pointer;
  top: 460px;
  right: 200px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #f7f7f9;
  line-height: 60px;
  text-align: center;
}
.CustomerStories {
  padding: 88px 0px 141px 0px;
  background-color: #f9fafc;
  width: 100%;
}
.CustomerStories-title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.CustomerStories-box {
  margin-top: 84px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // &:after {
  //   content: "";
  //   width: 274px;
  //   height: 355px;
  // }
}
.CustomerStories-box-item {
  width: 274px;
  height: 355px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(203, 197, 197, 0.26);
  border-radius: 8px;
  margin-bottom: 50px;
  cursor: pointer;
}
.imgs {
  width: 100%;
}
.CustomerStories-box-item-title {
  width: 60px;
  height: 25px;
  background: #2fc1a0;
  opacity: 0.8;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  text-align: center;
  margin: 18px 0px 20px 0px;
}
.CustomerStories-box-item-content {
  margin-bottom: 24px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  padding-right: 20px;
  height: 39px;
}
.CustomerStories-imgtitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ccc;
  margin-top: 12px;
  margin-left: 16px;
}
</style>
