import axios from "axios";

import { Message } from "element-ui";

const baseURL = process.env.VUE_APP_APIURL;
const request = axios.create({
    baseURL,//发版时把注释打开
    timeout: 5000
});

// 添加请求拦截器
request.interceptors.request.use(function (config) {
    config.headers = {
        'client-type': 'H5-PC',// 客户端类型PC H5
		'tenant-id': process.env.VUE_APP_TENANT_ID, //测试租户（正式租户【41环境】：1655393026849378304）(测试租户【122环境】：1531154512344256512)
		'third-session': '',
    }
    // console.log(config)
    // 在发送请求之前做些什么
    return config;
}, function (error) {
// 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    /**
     * http请求协议状态，不为200时，是失败。为200是成功
     */
    const data = response.data;
    if(data.code === 0) {
        return Promise.resolve(data);
    }else{
        Message.info(data.message);
        return Promise.reject(data);
    }
}, function (error) {
    // console.log("+++", error);
    const data = JSON.parse(error.request.response);
      Message.info(data.message);
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default request;