<template>
  <div class="Miniprogram">
    <!-- 头部 -->
    <common-nav @clickBtn="toFooterWrap" />
    <!-- 广告 -->
    <custom-banner
      :data="bannerData"
      :mode="2"
      layout="left"
      theme="#04CB94"
      text_color="black"
      :border-radius="50"
      :is-cns="isCns"
      @clickBtn="toFooterWrap"
    ></custom-banner>
    <!-- 美业小程序下面的box -->
    <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox">
        <div class="title">{{$t('footer.linkTitle1_label2')}}</div>
        <div class="desc">{{$t('MiniProgram.box1_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MiniProgram.box1_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/aebcefdd-bc43-4fb0-bcb0-715231e74068.png" alt="" class="img1">
      </div>
    </div>
    </div>
    <div class="Miniprogram-box container">
         <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/029fdf74-c678-4ce4-9418-ff5d8064c7a1.png" alt="" class="img2">
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('footer.linkTitle1_label3')}}</div>
        <div class="desc">{{$t('MiniProgram.box2_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
           {{$t('MiniProgram.box2_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
    <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox">
        <div class="title">{{$t('home.dataF6_list1_obj1_iconText2_2')}}</div>
        <div class="desc">{{$t('MiniProgram.box3_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
           {{$t('MiniProgram.box3_content2')}}
          
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/98b1b882-6217-4d68-8db3-a3c3804393c8.png" alt="" class="img3">
      </div>
    </div>
    </div>
     <div class="Miniprogram-box container ">
         <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/a12f1a78-dfae-4e6e-b41b-a7960f361c89.png" alt="" class="img4">
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MiniProgram.box4_title')}}</div>
        <div class="desc">{{$t('MiniProgram.box4_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
           {{$t('MiniProgram.box4_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
    <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox">
        <div class="title">{{$t('home.dataF4_title10')}}</div>
        <div class="desc">{{$t('MiniProgram.box5_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
         {{$t('MiniProgram.box5_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/60039cf6-cc1a-4822-9ec3-919f06b31ee1.png" alt="" class="img5">
      </div>
    </div>
    </div>
    <div class="Miniprogram-box container ">
         <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/9182e497-9dcd-4269-b862-c9abfe3474db.png" alt="" class="img6">
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MiniProgram.box6_title')}}</div>
        <div class="desc">{{$t('MiniProgram.box6_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
         {{$t('MiniProgram.box6_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
     <div class="minbox">
        <div class="Miniprogram-box container">
      <div class="Miniprogram-box-contentbox">
        <div class="title">{{$t('MiniProgram.box7_title')}}</div>
        <div class="desc">{{$t('MiniProgram.box7_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
         {{$t('MiniProgram.box7_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg">
          <img src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/de1f83bc-f9ab-4142-8c56-a148f54624bf.png" alt="" class="img7">
      </div>
    </div>
    </div>

    <!-- 回到顶部 -->
    <backTOP />

    <!-- 页脚 -->
    <div id="footer">
      <custom-footer />
    </div>
  </div>
</template>

<script>
// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue";
// 广告图组件
import customBanner from "@/components/customBanner/index.vue";
// 页脚
import customFooter from "@/components/customFooter/index.vue";
export default {
  name: "OfficialMeiyewebIndex",
  components: {
    commonNav,
    customBanner,
    customFooter,
  },
  data() {
    return {
      isCns: false,
      bannerData: [
        {
          title: this.$t('nav.beautyIndustryMiniProgram'),
          // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
          banner:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/9ae2fc45-46fe-4562-9bb0-3bb7a5c75326.png",
          subtitle: this.$t('MiniProgram.bancontent1'),
          content: [
            this.$t('MiniProgram.bancontent2'),
          ],
          button: this.$t("home.bannerData_button1"),
        },
        // {
        //   title: this.$t("home.bannerData_title2"),
        //   // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
        //   banner:
        //     "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f5a5e209-faa4-483b-9fea-72b1c393dc22.png",
        //   subtitle: this.$t("home.bannerData_subtitle2"),
        //   content: [],
        //   button: this.$t("home.bannerData_button2"),
        // },
      ],
    };
  },

  mounted() {},

  methods: {
    /**
      * 锚点定位
      */
    toFooterWrap() {
      document.querySelector('#footer').scrollIntoView({
				behavior: "smooth"
		  });
    },
  },
};
</script>

<style lang="less" scoped>
.minbox{
    width: 100%;
    background-color: #F9FAFC;
}
.minleft{
    margin-left: 151px;
}
.Miniprogram {
  &-box {
    // height: 651px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;
    &-contentbox {
      width: 782px;
      height: 555px;
      display: flex;
      padding-top: 120px;
      flex-direction: column;
      
      .title {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .desc {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 11px;
      }
      .heng {
        width: 60px;
        height: 6px;
        background: #04cb94;
        margin: 24px 0px 29px 0px;
      }
      .content {
        width: 512px;
       
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
      .btns {
        width: 111px;
        height: 43px;
        background: #04cb94;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 43px;
        margin-top: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
    &-pontimg{
        .img1{
            width: 782px;
            height: 555px;
        }
        .img2{
            width: 570px;
            height: 570px;
        }
        .img3{
            width: 758px;
            height: 472px;
        }
        .img4{
            width: 566px;
            height: 502px;
        }
        .img5{
            width: 716px;
            height: 316px;
        }
        .img6{
            width: 616px;
            height: 530px;
            margin-top: 50px;
        }
        .img7{
            width: 508px;
            height: 430px;
        }
    }
  }
}
</style>
