<template>
  <div>
    <div class="skind-box" animated>
      <div class="container flexbox">
        <div class="title">体态识别</div>
        <div class="detail">
          支持嵌入小程序、APP、平板、智能机器人，智能检测人体关键点，通过大数据分析对比，科学判断高低肩、脊柱异位等情况
        </div>
        <div class="flex tabs-box">
          <div
            class="identify-img cs03"
            v-for="(item, index) in identifyList"
            :key="index"
            @click="identifyIndex = index"
          >
            <el-image
              :src="index == identifyIndex ? item.selectImg : item.Img"
              :class="[
                index == identifyIndex
                  ? 'select-identify-img'
                  : 'wei-identify-img',
              ]"
              style="cursor: pointer"
            ></el-image>
            <div class="identify-box">
              <div
                :class="[
                  index == identifyIndex
                    ? 'select-identify-text'
                    : 'wei-identify-text',
                ]"
                class="identify-box-title"
              >
                拍照
              </div>
              <div
                :class="[
                  index == identifyIndex
                    ? 'select-identify-text'
                    : 'wei-identify-text',
                ]"
                class="identify-box-detail"
              >
                上传正面及侧面全身照片
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skind-boxd" animated>
      <div class="container flexbox">
        <div class="title">更多AI体态检测应用</div>
        <div class="detail">AI加码，创造更多可能</div>
        <div class="tabs-box">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              :label="item.label"
              :name="item.index"
              v-for="item in medicalscience"
              :key="item.label"
            >
              <div class="tabimg-item cs02">
                <div class="tabimg-item-rigbox">
                  <div class="rigbox-title">{{ item.title }}</div>
                  <div class="rigbox-detail">{{ item.detail }}</div>
                  <div class="rigbox-list">
                    <div
                      :class="
                        idindex == 1 ? 'rigbox-list-more' : 'rigbox-list-item'
                      "
                      v-for="(it, idindex) in item.introduce"
                      :key="it"
                    >
                      {{ it }}
                    </div>
                  </div>
                </div>
                <div class="tabimg">
                  <el-image
                    style="width: 100%; height: auto"
                    :src="item.img"
                    fit="contain"
                  ></el-image>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="skind-box">
      <div class="container flexbox">
        <div class="title">合作伙伴成功案例</div>
        <div class="detail">洞察科技赋能品牌商，创新引领业务增长</div>
        <div class="carousel-box">
          <!-- 轮播组件 -->
          <Carousel :list="CarouselList"></Carousel>
        </div>
      </div>
    </div>
    <div class="skind-boxd">
      <div class="container flexbox">
        <div class="title">更多AI应用</div>
        <div class="detail">科技加码创新力</div>
        <div class="flex-box">
          <div
            class="AI-boxitem"
            v-for="item in AIbox"
            :style="{ 'background-image': `url(${item.bgimg})` }"
          >
            <div class="AI-boxitem-title">{{ item.title }}</div>
            <div class="AI-boxitem-detail">
              {{ item.detail }}
            </div>
            <div class="AI-boxitem-btn">了解详情 ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "./Carousel.vue";
export default {
  name: "OfficialMeiyewebPhysicalexamination",
  components: {
    Carousel,
  },
  data() {
    return {
      activeName: "1",
      medicalscience: [
        {
          label: "BMI体脂称",
          index: "1",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/aec99117-966c-41df-84ea-b3d563d460a8.png",
          title: "BMI体脂称",
          detail:
            "计算用户的体脂率(bfr)，身体质量指数(bmi)，基础代谢率(bmr)，帮助用户检测身体状况，辅助减肥，健康每一天",
          introduce: ["立即咨询", "了解更多"],
        },
        {
          label: "AI足弓识别",
          index: "2",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/570ae7f4-8635-42e4-bbf1-df292c1c19f7.png",
          title: "AI足弓识别",
          detail:
            "小孩子光脚站在一起设备上，设备仪器将会智能采集小孩子的脚底数据，智能分析小孩子的足底数据是否存在扁平足，高弓足等异常情况",
          introduce: ["立即咨询", "了解更多"],
        },
        {
          label: "未来身高预测",
          index: "3",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/6e932547-8bd6-4142-add0-219c34ddb22f.png",
          title: "未来身高预测",
          detail:
            "对父母和小孩子当前身高数据进行采集，通过专业权威的智能算法推算出小孩子当前年龄的预期身高，以及成人后的实际身高",
          introduce: ["立即咨询", "了解更多"],
        },
        {
          label: "AI体态检测",
          index: "4",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/ac2138e8-b5b3-469b-82bf-f84be986283c.png",
          title: "AI体态检测",
          detail:
            "使用设备仪器给人体正面和侧面拍摄照片，即可通过智能分析平台智能检测人体关键点，从而给用户分析体态是否正常发育，是否有高低肩，是否有脊柱异位",
          introduce: ["立即咨询", "了解更多"],
        },
      ],
      CarouselList: [
        {
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/042f0160-6b3a-458a-bdb9-d08079c8bb3e.png",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/74219893-3b83-436d-b14f-550a65348c95.png",
          title: "",
          detail:
            "儿童功能鞋领先品牌，借力AIoT技术，研发耐力康智能体态检测太空舱+小程序商城，智能检测儿童扁平足特征并推荐矫正鞋，线上线下相结合赋能门店提高产品销量，短短几年间，耐力康全国专柜已发展至2000+。",
          url: "https://mp.weixin.qq.com/s/6jJkVavg2ZGxio3F0N-Ijg",
        },
        {
          iconimg: "",
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/90353a35-7f74-480b-a931-a73ab2d30b08.png",
          title: "林至集团",
          detail:
            "高分子材料科技有限公司，应用人工智能+物联网技术，通过柔性传感器打造的智能鞋垫，帮助品牌商打造用户可定制的智能矫正鞋垫以实现改善体态的目的。",
          url: "https://mp.weixin.qq.com/s/IF-sZfFfOgEKpeZUfx62Gw",
        },
      ],
      application: [
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/7297d5d4-34fc-4e47-8230-d8619a888049.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ecef1264-2804-4585-878b-d524e5e1646f.png",
          title: "医院",
          detail: "中医治病科室",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/ecbe88a5-a285-463a-9e71-5c84f7067aef.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/23edd3bd-0a98-4b57-a63d-dcee548f3611.png",
          title: "药店",
          detail: "药品推荐个性化买药",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/493bd27d-bbb3-4039-a73b-65e03b861b68.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/cd5f64d0-3c47-4406-9136-c64d126fe2d0.png",
          title: "养老院",
          detail: "关爱老年人",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/91679cca-bf98-49de-82e4-0a03c15b76bb.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/84dc2a86-cf8c-4682-bcf9-b491c8a22683.png",
          title: "社区",
          detail: "营造健康社区",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/227cc9b5-b206-444f-902d-4e2f63c19c52.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/61f04442-413b-4f29-9127-804548c24676.png",
          title: "月子中心",
          detail: "关注母婴健康",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5a9ebaed-aee8-47a9-972c-a6e52e4b7001.png",
          iconimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/b4fffb22-2dce-47b5-bbec-dff5b88ce454.png",
          title: "旅游",
          detail: "医疗旅游区域",
        },
      ],
      AIbox: [
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/30e5e67d-bae9-4348-b460-c26fd804e718.png",
          title: "AI体质检测",
          detail: "智能中医问诊，AI实现望闻问切，赋能中医馆、中小诊所等领域",
        },
        {
          bgimg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/d3f5d481-0515-4dfa-acd8-3ec70f1d8499.png",
          title: "AI皮肤检测",
          detail:
            "精准皮肤检测，30+维度肤质问题快速检测，适合化妆品电商、美容仪电商、皮肤管理机构、医美机构等场所",
        },
      ],
      algorithmList: [
        {
          title: "更快识别",
          cont: "识别快30秒",
          detail:
            "实现中医四诊，望、闻、问、切，快速识别体质类型，直接展示体质报告及对应的专家推荐调理方案",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/fe7ada0c-5ed5-4247-8068-da8dac9acc2f.png",
        },
        {
          title: "更准判断",
          cont: "精准率＞91.2%",
          detail:
            "专家测评，联合广东省人工智能中医工程中心组织多次名老中医专家背靠背测评，准确率＞91.2%",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/fd1613be-3b50-439d-980d-ccd0344fc1a1.png",
        },
        {
          title: "更全面强大",
          cont: "中医知识图谱",
          detail:
            "英国皇家科学院和工程院两院院士，华南理工大学机器学习与数据挖掘实验室主任，广东省人工智能中医工程技术研究中心高级研究员+16位老中医+15位博士共同打造的中医养生知识库",
          img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/588952f5-01b0-476e-b8a5-63c914d0e075.png",
        },
      ],
      identifyList: [
        {
          selectImg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/1b60c5ae-8b35-4a8c-9c16-ae5a32a6ac6f.png",
          Img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/d2643989-6e9d-46cf-8c48-e1a2975ce611.png",
          title: "拍照",
          detail: "上传正面及侧面全身照片",
        },
        {
          selectImg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/d2570327-aafb-4727-8824-b7d168a00b4e.png",
          Img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/b388150a-2314-451d-9614-8467bd89afda.png",
          title: "识别",
          detail: "云端采集分析识别人体关键点",
        },
        {
          selectImg:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/20c4ed6d-6c78-47e6-a4f5-6eb83fc97358.png",
          Img: "https://tpsite.oss-cn-hangzhou.aliyuncs.com/1687069715383717888/mall/user/b1757c23-6739-4a4f-b83c-0668144bfd7f.png",
          title: "报告",
          detail: "体态数据+风险评估+康复方案",
        },
      ],
      identifyIndex: 0,
    };
  },

  mounted() {},

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}
.tabimg {
  width: 592px;
}
.channel-img {
  width: 300px;
}
/**添加切换动画 */
.cs02 {
  animation: fadeInDown 1s 0.02s ease backwards;

  /*解释：
* fadeInleft 是引用插件中的一个动画名称
* 1s         是点击时，这个动画整体使用的时间
* 0.02s      是动画刚开始显示需要的时间
* ease       是动画开始的方向
* backwards  有啥效果，鄙人目前还没看出来，百度查的说是“规定对象动画时间之外的状态”
*/
}
.cs03 {
  animation: fadeIn 1s 0.02s ease backwards;
}
/deep/ .el-carousel__indicator--horizontal {
  padding: 45px 4px;
}
/deep/ .el-carousel__button {
  height: 6px;
}
/deep/ .el-carousel__indicator.is-active button {
  background: #04cb94;
}
/deep/ .el-tabs__nav-scroll {
  padding-left: 250px;
}
/deep/ .el-tabs__item {
  line-height: 20px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  // margin-right: 60px;
  padding: 0px 60px;
}
/deep/ .el-tabs__item.is-active {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2ac4a3;
}
/deep/ .el-tabs__active-bar {
  width: 134px !important;
  height: 4px;
  background: #3cd2ad;
  margin-left: -20px;
}
.skind-boxd {
  background: #fff;
  width: 100%;
  padding: 77px 0px 144px 0px;
}
.skind-box {
  background: #f9fafc;
  width: 100%;
  padding: 99px 0px 178px 0px;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 17px;
}
.detail {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.tabs-box {
  width: 100%;
  margin-top: 66px;
}
.tabimg-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 68px;
  &-rigbox {
    display: flex;
    flex-direction: column;
    margin-top: 86px;
  }
  .rigbox-title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  .rigbox-detail {
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 487px;
    margin-top: 33px;
  }
  .rigbox-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    width: 422px;
    &-item {
      width: 138px;
      height: 48px;
      background: #24c9a5;
      border: 2px solid #2aca9e;
      border-radius: 27px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 16px;
      text-align: center;
      line-height: 48px;
      margin-right: 22px;
    }
    &-more {
      width: 138px;
      height: 48px;
      border: 2px solid #04cb94;
      border-radius: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #04cb94;
      text-align: center;
      line-height: 48px;
    }
  }
}
.algorithm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 62px;
}
.algorithm-box {
  margin-top: 93px;
}
.algorithm-title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.algorithm-cont {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin: 12px 0px 27px 0px;
}
.algorithm-gan {
  width: 47px;
  height: 6px;
  background: #28c6a4;
  margin-bottom: 22px;
}
.algorithm-detail {
  width: 448px;
  height: 53px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
}
.channel-box {
  margin-top: 53px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &-item {
    width: 357px;
    height: 396px;
    background: #ffffff;
    box-shadow: 4px 6px 18px 0px rgba(203, 197, 197, 0.26);
    border-radius: 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 26px;
    box-sizing: border-box;
    margin-left: 43px;
  }
}
.channel-box-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2d2d2d;
  margin: 35px 0px 27px 0px;
}
.channel-box-detail {
  width: 310px;
  height: 39px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
}
.carousel-box {
  width: 1100px;
  height: 424px;
  margin-top: 44px;
}
.application-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 92px;

  &-item {
    width: 324px;
    height: 290px;
    margin-left: 40px;
    padding: 120px 0px 41px 26px;
    margin-bottom: 32px;
  }
}
.application-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin: 12px 0px;
}
.application-detail {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.AI-boxitem {
  width: 689px;
  height: 320px;
  padding: 71px 0px 93px 98px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  &-title {
    font-size: 24px;
    font-family: MiSans;
    font-weight: bold;
    color: #333333;
  }
  &-detail {
    width: 250px;
    font-size: 14px;
    font-family: MiSans;
    font-weight: 300;
    color: #666666;
    line-height: 25px;
    margin: 29px 0px;
  }
  &-btn {
    font-size: 14px;
    font-family: MiSans;
    font-weight: 400;
    color: #04cb94;
  }
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.identify-img {
  margin-left: 20px;
  position: relative;
}
.identify-box {
  position: absolute;
  bottom: 42px;
  left: 32px;
  font-family: Microsoft YaHei;
  &-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &-detail {
    font-size: 20px;
    font-weight: 400;
  }
}
.select-identify-img {
  width: 500px;
  height: 512px;
  transition: all 0.3s linear;
}
.wei-identify-img {
  width: 328px;
  height: 512px;
  transition: all 0.3s linear;
}
.select-identify-text {
  color: #fff;
}
.wei-identify-text {
  color: #333333;
}
</style>
