<!-- 广告图组件 -->

<template>
    <div>
        <div :class="['custom-banner_box', layout, fontColor]" v-if="mode == 1">
            <img :src="data.banner" />
            <div class="banner_wrap container_box">
                <h1 >{{ data.title }}</h1>
                <h2 v-if="data.subtitle" >{{ data.subtitle }}</h2>
                <div v-if="data.content && data.content.length">
                    <p v-for="(content, contentIndex) in data.content" :key="contentIndex">{{ content }}</p>
                </div>
                <div class="banner_lable" v-if="data.lable">
                    <div class="banner_lable_item" v-for="(lableItem, lableIndex) in data.lable" :key="lableIndex">
                        <img :src="lableItem.icon" />
                        <span>{{ lableItem.text }}</span>
                    </div>
                </div>
                <button :style="[{ 'background-color': theme }, { 'border-radius': (borderRadius + 'px') }]"
                    v-if="data.button" @click="handleConsult">{{ data.button }}</button>
            </div>
        </div>
        <div :class="['custom-banner_box', layout, fontColor]" v-else-if="mode == 2">
            <el-carousel :height="heightCopy + 'px'">
                <el-carousel-item v-for="(item, index) in data" :key="index">
                    <img ref="bannerImg" :src="item.banner" @load="imgLoad(index)" @click="handleConsult(item)" />
                    <div class="banner_wrap container_box" :style="{ color: text_color }">
                        <h1 >{{ item.title }}</h1>
                        <h2 v-if="item.subtitle" :style="[{ 'font-size': (subtitleSize + 'px'),width:'700px' }]">{{ item.subtitle }}</h2>
                        <div v-if="item.content && item.content.length" >
                            <p :style="{ color: text_color,width:'700px' }" v-for="(content, contentIndex) in item.content"
                                :key="contentIndex">{{ content }}</p>
                        </div>
                        <div class="banner_lable" v-if="item.lable">
                            <div class="banner_lable_item" v-for="(lableItem, lableIndex) in item.lable" :key="lableIndex">
                                <img :src="lableItem.icon" />
                                <span>{{ lableItem.text }}</span>
                            </div>
                        </div>
                        <button :style="[{ 'background-color': theme }, { 'border-radius': (borderRadius + 'px') }]"
                            v-if="item.button" @click="handleConsult(item)">{{ item.button }}</button>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
    name: "customBanner",

    props: {
        mode: { // 模式：1=非轮播广告图，2=轮播广告图
            type: Number,
            default: 1
        },
        data: {
            type: Object | Array,
            default: null
        },
        layout: { // 布局：left，center，right
            type: String,
            default: 'center'
        },
        theme: { // 主题色
            type: String,
            default: ''
        },
        fontColor: { // 文本色：white，black
            type: String,
            default: 'white'
        },
        text_color: { // 文本色：white，black
            type: String,
            default: 'white'
        },
        borderRadius: { // 按钮圆角
            type: Number,
            default: 0
        },
        height: {
            type: String,
            default: null
        },
        isCns: {
            type: Boolean,
            default: false
        },
        subtitleSize: {
            type: String,
            default: '',
        },
    },

    mounted() {
        let that = this;
        window.onresize = () => {
            let bannerImg = that.$refs.bannerImg;
            let height = 0;
            bannerImg.forEach(item => {
                if (item.offsetHeight > height) {
                    height = item.offsetHeight;
                }
            });
            that.heightCopy = height;
        }
    },

    data() {
        return {
            heightCopy: this.height ? this.height : 427,
        }
    },

    methods: {
        imgLoad(idx) {
            let imgObj = this.$refs.bannerImg[idx];
            if (imgObj.offsetHeight > this.heightCopy) {
                this.heightCopy = imgObj.offsetHeight;
            }
        },

        test() {
            console.log('点击了我')
        },

        // 免费咨询
        handleConsult(item) {
            if(item.openUrl){
                window.open(item.openUrl)
            }else{
                this.$emit('clickBtn')
            }
            // if (!this.isCns) {
            //     window.open(
            //         'https://tb.53kf.com/code/client/a35a0e3e8c85a6d8df443eec771d6eb10/1')
            // } else {
            //     window.open(
            //         'http://p.qiao.baidu.com/cps/chat?siteId=14912370&userId=30331076&siteToken=798cbd327d4884415737486bfe7fe0b6')
            // }
        },
    }
}
</script>

<style lang="less" scoped>
// banner
.custom-banner_box {
    width: 100%;
    position: relative;

    >img {
        width: 100%;
    }

    .el-carousel {

        .el-carousel__container {

            .el-carousel__item {

                >img {
                    width: 100%;
                }
            }
        }

        .el-carousel__indicators {

            .el-carousel__button {
                height: 8px;
                border-radius: 4px;
                opacity: .5;
            }

            .el-carousel__indicator {

                .el-carousel__button {
                    width: 16px;
                }
            }

            .is-active {
                .el-carousel__button {
                    width: 30px;
                    opacity: 1;
                }
            }
        }
        // 轮播图下面的指示器
        /deep/ .el-carousel__indicator--horizontal {
            padding: 45px 4px;
        }

        /deep/ .el-carousel__button {
            height: 6px;
        }

        /deep/ .el-carousel__indicator.is-active button {
            background: #04CB94;
        }
    }

    .banner_wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        z-index: 10;

        >h1 {
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 19px;
            // 支持换行
            width: 800px;
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            // 支持换行结束
        }

        >h2 {
            font-size: 40px;
            margin-bottom: 21px;
            // 支持换行
            width: 800px;
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            // 支持换行结束
        }

        p {
            margin: 10px 0;
            // 支持换行
            width: 800px;
            white-space: normal;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            // 支持换行结束
        }

        >.banner_lable {
            width: 100%;
            display: flex;
            justify-content: space-around;
            margin-top: 68px;

            .banner_lable_item {
                display: flex;
                flex-direction: column;
                align-items: center;

                >img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-bottom: 18px;
                    padding: 32px;
                    background-color: rgba(255, 255, 255, .1);
                }

                >span {
                    font-size: 18px;
                    color: #fff;
                }
            }
        }

        >button {
            color: #fff;
            font-size: 20px;
            text-align: center;
            background-color: #2F81F9;
            padding: 8px 30px;
            margin-top: 15px;
        }
    }

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.black {

        h1 {
            color: #333;
        }

        h2 {
            color: #333;
        }

        p {
            color: #666;
        }
    }
}

// banner end

// tabs
.custom_tabs_box {

    .el-tabs__header {
        margin-bottom: 0;
    }

    .el-tabs__content {
        overflow: visible;
    }

    .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #EEE;
    }

    .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;

        .el-tabs__item {
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            padding: 0 100px;

            &.is-active {
                color: #0070FF;
            }

            &.is-top:nth-child(2) {
                padding-left: 0;
            }
        }

        .el-tabs__active-bar {
            background-color: #0070FF;
        }

        .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
        .el-tabs--bottom .el-tabs__item.is-top:last-child,
        .el-tabs--top .el-tabs__item.is-bottom:last-child,
        .el-tabs--top .el-tabs__item.is-top:last-child {
            padding-right: 0;
        }
    }

    .tabs_item {
        padding-top: 50px;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(2, 1fr);

        .tabs_item_left {
            display: flex;


            &.tabs_item_left2 {
                flex-direction: column;
                justify-content: space-around;
            }

            &.tabs_item_left3 {

                >img {
                    width: 72px;
                    height: 72px;
                    margin: 50px 0 0 100px;
                }
            }

            .tabs_item_icon {
                width: 72px;
                height: 72px;
                margin: 50px 0 0 100px;
            }

            .til_info {
                padding: 50px;
                border-radius: 10px;

                h4 {
                    font-size: 24px;
                    color: #212121;
                }

                p {
                    font-size: 16px;
                    color: #757F92;
                    margin-top: 20px;
                }

                .tili_lable {
                    margin-top: 20px;
                    display: grid;
                    gap: 18px 30px;
                    grid-template-columns: repeat(4, auto);

                    .tili_lable_item {
                        width: 120px;
                        height: 32px;
                        line-height: 32px;
                        border: 1px solid #DDDDDD;
                        border-radius: 16px;
                        font-size: 16px;
                        text-align: center;
                    }
                }

                .button {
                    margin-top: 50px;
                    padding: 0 25px;
                    font-size: 14px;
                    color: #fff;
                    height: 32px;
                    line-height: 32px;
                    border-radius: 16px;
                }

                .bg_button {
                    margin-top: 60px;
                    background-size: 100%;
                    width: 168px;
                    height: 42px;
                }
            }

            .til_curr {
                box-shadow: 0px 4px 20px 0px #F2F6FA;
            }
        }

        .tabs_item_right {
            display: flex;
            align-items: center;
        }
    }

}

// tabs end

// drawer
.custom-drawer_box {
    display: flex;
    width: 100%;

    .drawer_item {
        color: #fff;
        padding: 80px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: width linear .3s;

        >h4 {
            font-size: 24px;
        }

        >span {
            font-size: 16px;
            margin: 20px 40px;
        }

        >p {
            max-width: 240px;
            min-height: 90px;
            font-size: 16px;
            line-height: 30px;
        }

        >button {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #fff;
            margin: 40px 0;

            .triangle_right {
                display: block;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-left: 10px solid #fff;
                border-bottom: 5px solid transparent;
                margin-left: 20px;
            }
        }

        >img {
            max-width: 280px;
        }
    }
}

.custom-drawer_box2 {
    display: flex;
    width: 100%;

    .drawer_item {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        transition: all linear .3s;

        &:last-of-type {
            margin-right: 0;
        }


        .drawer_item_info {
            position: absolute;
            top: 106px;
            left: 50%;
            transform: translateX(-50%);

            &.curr_drawer_item_info {
                left: 47px;
                transform: translateX(0);
            }

            .drawer_item_icon {
                width: 50px;
                height: 50px;
            }

            .drawer_item_content {
                max-width: 260px;
                font-size: 20px;
                color: #fff;
                line-height: 30px;
                margin-top: 20px;

                >i {
                    font-weight: bold;
                    margin-right: 15px;
                }
            }
        }

        .drawer_item_bg {}
    }
}

.custom-drawer_box3 {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    .drawer_item {
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        padding: 60px 40px;
        border: 1px solid #fff;
        transition: all linear .3s;

        >h4 {
            font-size: 20px;
            margin: 40px 0 20px;
        }

        >P {
            color: #999999;
            font-size: 16px;
            line-height: 30px;
        }

        &:hover {
            transform: translateY(-32px);
        }
    }
}

.custom-drawer_box4 {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    .drawer_item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;
        background-color: #fff;
        border: 1px solid #fff;
        transition: all linear .3s;

        &:hover {
            z-index: 10;
        }

        >img {
            margin-right: 30px;
        }

        .drawer_item_info {

            >h4 {
                font-size: 22px;
            }

            >p {
                font-size: 16px;
                color: #999;
                margin-top: 15px;
                line-height: 30px;
            }
        }
    }
}

.custom-drawer_box5 {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    .drawer_item {
        position: relative;

        >img {
            width: 100%;
        }

        .drawer_item_info {
            position: absolute;
            width: 100%;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            bottom: 0;

            >h4 {
                font-size: 16px;
                text-align: center;
            }

            >p {
                font-size: 16px;
                color: #999;
                text-align: center;
            }
        }
    }
}

.custom-drawer_box6 {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);

    .drawer_item {
        display: flex;
        flex-direction: column;
        align-items: center;

        >img {
            width: 100%;
        }

        >h4 {
            font-size: 32px;
            text-align: center;
        }

        >p {
            font-size: 16px;
            color: #999;
            text-align: center;
            margin-top: 14px;
        }
    }
}

// drawer end

// contact
.custom-contact_box {
    position: relative;
    overflow: hidden;
    height: 266px;

    .custom-contact_bg {
        position: absolute;
        min-width: 100%;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    .custom-contact_wrap {
        margin: 110px auto 0;

        >h1 {
            font-size: 32px;
            color: #fff;
            width: 1380px;
            margin: 0 auto;
        }

        .input_box {
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(5, 1fr);
            justify-content: center;
            margin-top: 25px;

            .el-input {

                >input {
                    width: 100%;
                    height: 48px;
                    font-size: 18px;
                    color: #999;
                }
            }

            >button {
                height: 48px;
                border-radius: 5px;
                color: #fff;
                font-size: 20px;
                white-space: nowrap;
                background-color: #2BDFAC;
            }

            .code_wrap {
                position: relative;

                .el-input {

                    >input {
                        padding-right: 100px;
                    }
                }

                >span {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                    font-size: 18px;
                    color: #2ECDBA;
                    cursor: pointer;
                }
            }
        }
    }
}

// contact end

// 媒体查询
@media screen and (max-width:768px) {
    .container_box {
        width: 100%;
    }

    // banner
    .custom-banner_box {

        .banner_wrap {
            padding-left: 20px;

            >h1 {
                font-size: 18px;
            }

            >h2 {
                font-size: 16px;
            }

            >p {
                font-size: 12px;
                margin: 6px 0;
            }

            >.banner_lable {
                margin-top: 2px;

                .banner_lable_item {

                    >img {
                        width: 20px;
                        height: 20px;
                        margin-bottom: 2px;
                        padding: 18px;
                    }

                    >span {
                        font-size: 12px;
                    }
                }
            }

            >button {
                font-size: 12px;
                padding: 4px 24px;
                margin-top: 10px;
            }
        }
    }

    // banner end
}

@media screen and (min-width:768px) {
    .container_box {
        width: 750px;
    }

    // banner
    .custom-banner_box {

        .banner_wrap {

            >h1 {
                font-size: 20px;
            }

            >h2 {
                font-size: 18px;
            }

            >p {
                font-size: 14px;
                margin: 6px 0;
            }

            >.banner_lable {
                margin-top: 10px;

                .banner_lable_item {

                    >img {
                        width: 28px;
                        height: 28px;
                        margin-bottom: 10px;
                        padding: 24px;
                    }

                    >span {
                        font-size: 14px;
                    }
                }
            }

            >button {
                font-size: 12px;
                padding: 4px 24px;
                margin-top: 10px;
            }
        }
    }

    // banner end
}

@media screen and (min-width:992px) {
    .container_box {
        width: 970px;
    }

    // banner
    .custom-banner_box {

        .banner_wrap {

            >h1 {
                font-size: 40px;
            }

            >h2 {
                font-size: 32px;
            }

            >p {
                font-size: 16px;
                margin: 8px 0;
            }

            >.banner_lable {
                margin-top: 40px;

                .banner_lable_item {

                    >img {
                        width: 32px;
                        height: 32px;
                        margin-bottom: 14px;
                        padding: 26px;
                    }

                    >span {
                        font-size: 16px;
                    }
                }
            }

            >button {
                font-size: 12px;
                padding: 6px 28px;
                margin-top: 12px;
            }
        }
    }

    // banner end
}

@media screen and (min-width:1200px) {
    .container_box {
        width: 1170px;
    }

    // banner
    .custom-banner_box {

        .banner_wrap {

            >h1 {
                font-size: 48px;
                font-weight: bold;
            }

            >h2 {
                // font-size: 40px;
                font-size: 26px;
            }

            >p {
                font-size: 26px;
                margin: 10px 0;
            }

            >.banner_lable {
                margin-top: 68px;

                .banner_lable_item {

                    >img {
                        width: 40px;
                        height: 40px;
                        margin-bottom: 18px;
                        padding: 32px;
                    }

                    >span {
                        font-size: 18px;
                    }
                }
            }

            >button {
                font-size: 16px;
                padding: 15px 36px;
                margin-top: 46px;
                border-radius: 2px !important;
            }
        }
    }

    // banner end
}
</style>