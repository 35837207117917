<template>
    <div class="nav-body">
        <div>
            <div class="header_nav_box">
                <div class="navs">
                    <!-- logo -->
                    <div class="logo-wrap">
                        <a href="/" >
                            <img :src="defaultLanguage != 'en'?'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/5be5cbcd-5251-4f1d-b5e2-7ae2591e8889.png':'https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/d1c60a5d-1907-43f4-8a3a-f7c48f4a0038.png'"
                            alt="">
                        </a>
                    </div>
                    <!-- 导航文本 -->
                    <div class="head-navs">
                        <div v-for="(navItem, navIndex) in head_navs" :key="navIndex"
                            :class="[{ 'navItem': defaultLanguage != 'en', 'navItem_en': defaultLanguage == 'en', 'navItemActive': navIndex == num }]" @click="headnavs(navItem.link)">
                             <div v-if="navIndex !=4">{{ navItem.title }}</div>
                             <el-dropdown v-else placement="bottom" @command="command" @visible-change="visiblechange">
                               <span class="el-dropdown-link">{{navItem.title}}<i :class="visflag?'el-icon-arrow-up':'el-icon-arrow-down'" :style=" {'color':classicon?'#04CB94':'','font-weight': 'bold'}"></i></span>
                                <el-dropdown-menu slot="dropdown" >
                                    <el-dropdown-item v-for="(item,index) in MeiyeDropdown" :key="item.id" :command="item.id" :style="{'background':index==tabindex?'rgb(236, 245, 255)':'','color':index==tabindex?'#04CB94':''}">{{ item.name }}</el-dropdown-item>
                                 </el-dropdown-menu>
                              </el-dropdown>
                        </div>
                    </div>
                    <!-- 按钮 -->
                    <div class="button-wrap">
                        <div class="register" @click="tryFreeBtn">
                            <!-- <a href="http://www.qushiyun.com/index?platform=pc&path=register">免费试用</a> -->
                            {{ $t('nav.tryFree') }}
                        </div>
                    </div>

                </div>
                <!-- 语言切换 -->
                <div style="cursor: pointer;" class="flex">
                    <div v-for="(item,index) in langOptions" @click="changeLanguage(item.value)">
                        <span :class="[defaultLanguage == item.value?'langcolor':'']" >{{item.label}}</span>
                        <span v-if="index != langOptions.length-1">丨</span>
                    </div>
                </div>

                <!-- <div class="flex">

                    <el-select v-model="defaultLanguage" @change="changeLanguage" size="mini" style="width: 100px">
                        <el-option v-for="item in langOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// 国际化
import i18n from '@/lang/index'

export default {
    name: "commonNav",

    data() {
     return {
            visflag: '',//下拉收起的值
            classicon:true,
            host: "", //当前域名
            num:0,
            defaultLanguage: process.env.VUE_APP_DEFAULT_LANG || 'zh-cn', //国际化默认选中的语言
            langOptions: [
                {
                    label: "简体",
                    value: "zh-cn",
                },
                {
                    label: "繁体",
                    value: "zh-hk",
                },
                {
                    label: "English",
                    value: "en",
                }
            ], //语言
            MeiyeDropdown: [
             { id:'skin', name: 'AI 皮肤检测' },
             {id:'constitution',name:'AI 体质检测'},
             { id:'posture', name: 'AI 体态检测' },
            //  {id:'applications',name:'物联网应用'},
         ],
            tabindex:0,
            head_navs: [
                {
                    title: this.$t("nav.home"), //"首页",
                    link: "/",
                    showList: false,
                    childs: [],
                },
                {
                    title: this.$t("nav.beautyIndustryMiniProgram"), //"美业小程序",
                    link: '/MiniProgram',
                    showList: false,
                    childs: [],
                },
                {
                    title: this.$t("nav.beautyChain"), //"美业连锁",
                    link: '/MeiyeChain',
                    showList: false,
                    childs: [],
                },
                {
                    title: this.$t("nav.beautyDistribution"), //"美业分销",
                    link: "/MeiyeDistribution",
                    showList: false,
                    childs: [],
                },
                {
                    title: this.$t("nav.beautyAloT"), //"美业AloT",
                    link: "/MeiyeIot",
                    showList: false,
                    childs: [],
                },
                {
                    title: this.$t("nav.customerStories"), //"客户案例",
                    link: "/CustomerStories",
                    showList: false,
                    childs: [],
                },
                {
                    title: this.$t("nav.aboutUs"), //"关于我们",
                    link: "/AboutUs",
                    showList: false,
                    childs: [],
                },
            ]
        }
    },

    mounted() {
        this.host = window.location.host;
        let name = this.$router.history.current.name
        console.log(name, '路由名字');
        if (name !='MeiyeIot') {
         this.classicon = false;
         localStorage.setItem('tabindex','') //跳转别的路由清除缓存
        }
        switch(name){
            case 'home':
            this.num = 0
            break;
            case 'MiniProgram':
            this.num = 1
            break;
            case 'MeiyeChain':
            this.num = 2
            break;
            case 'MeiyeDistribution':
            this.num = 3
            break;
            case 'MeiyeIot':
            this.num = 4
            break;
            case 'CustomerStories':
            this.num = 5
            break;
            case 'AboutUs':
            this.num = 6
            break
        }
     this.defaultLanguage = localStorage.getItem('currentLang') || process.env.VUE_APP_DEFAULT_LANG || 'zh-cn'; // 设置当前页面使用的语言
     let tabindex = localStorage.getItem('tabindex')
     if (tabindex) {
      this.tabindex = tabindex
        }
    },

 methods: {
          //点击下拉选择跳转不同组件
         command(val) {
           this.$router.push({ name: 'MeiyeIot', query: { 'name': val } })
           this.$emit('MeiyeDropdown', val)
             let index = this.MeiyeDropdown.findIndex(item => item.id == val)
            this.tabindex = index
             localStorage.setItem('tabindex',this.tabindex) //防止刷新
             console.log(this.tabindex);
         },
  //监听下拉事件
  visiblechange(val) {
      this.visflag = val
  },
        //跳转不同页面
        headnavs(link){
            let currentPath = this.$route.path; //当前路由地址
            if(currentPath != link) {
                this.$router.push({path:link})
            }
        },
        // 国际化切换不同不同语言
        changeLanguage(val) {
            // console.log("切换语言=>", val);
            i18n.locale = val; //修改i18n的默认语言
            localStorage.setItem("currentLang", val); //将选择的语言放入缓存
            location.reload(); //重新加载整个页面，为了就是重新执行js文件的国际化处理
        },

        // 免费试用
        tryFreeBtn() {
            this.$emit('clickBtn')
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dropdown{
 font-size: 16px;
}
/deep/ .el-dropdown-menu__item {
 &:hover{
  cursor: pointer;
    color:#04CB94 !important;
    background-color: #fff;
 }

  }
.nav-body {
    margin-bottom: 60px;
}
.langcolor{
    color:rgb(4, 203, 148);
    font-weight: bold;

}
.header_nav_box {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    // line-height: 60px;
    z-index: 9;
    -webkit-transition: background-color .1s;
    transition: background-color .1s;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    // justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    // max-width: 1200px;
    height: 60px;
    // margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
    color: #666666;
    margin-left: 290px;
    // logo区域
    .logo-wrap {
        width: 114px;
        height: 32px;
        margin-right: 40px;

        img {
            width: 100%;
        }
    }

    // 导航栏文本
    .head-navs {
        display: flex;
        justify-content: space-between;
        flex: 1;

        // white-space: nowrap; /* 父级元素中的内容不换行 */
        // overflow-x: scroll;
        // overflow-y: hidden;
        .navItem {
            padding: 18px;
            cursor: pointer;
            color: #666666;
            min-width: 122px;
            text-align: center;
        }

        .navItem:hover {
            color: #04CB94;
        }

        // 英文状态下
        .navItem_en {
            // font-size: 14px;
            padding: 18px 10px;
            min-width: 122px;
            text-align: center;
            cursor: pointer;
            color: #666666;
        }

        .navItem_en:hover {
            color: #04CB94;
        }

        .navItemActive {
            color: #04CB94;
            border-bottom: 4px solid #04CB94;
        }
    }

    // 按钮
    .button-wrap {
        margin-left: 50px;

        .register {
            background: #04CB94;
            padding: 0 28px;
            height: 60px;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
}

// 语言切换
/deep/ .el-input__inner:focus {
    border-color: #04CB94 !important;
}

/deep/ .el-input.is-focus .el-input__inner {
    border-color: #04CB94 !important;
}
/deep/ .el-select-dropdown__item.selected {
    color: #04CB94 !important;
}
</style>