/**
 * 自定义英文语言包
 */

const en = {
  "nav.home": "Home",
  "nav.beautyIndustryMiniProgram": "Mini program",
  "nav.beautyChain": "Chain contro",
  "nav.beautyDistribution": "DRP",
  "nav.beautyAloT": "AIoT",
  "nav.customerStories": "Client case",
  "nav.aboutUs": "About us",
  "nav.tryFree": "Free demo",

  "footer.part1_title1": "Trendcloud,beauty industry business growth specialist",
  "footer.part1_text1":
    "Focus on providing solutions for brand owners,such as mini program,distribution system,AI skin detection,smart store and so on.",
  "footer.withContactUs": "Contact us",
  "footer.part1_text2": "There will be a special person to provide you with business advice, solutions, professional questions and so on.",
  "footer.phonePlaceholder": "Enter",
  "footer.submitBtn": "Appointment call back",
  "footer.contactUsTitle": "Contact Us",
  "footer.consultation": "Pre sales service consultation",
  "footer.contactCustomerService": "CUSTOMER SERVICE",
  "footer.officialAccount": "TRENDCLOUD official account",
  "footer.companyName": "Guangzhou Trend Information Technology Co., Ltd",
  "footer.icp": "Yue ICP Bei No. 18106639-1",
  "footer.linkTitle1": "Popular Links",
  "footer.linkTitle2": "Solution",
  "footer.linkTitle3": "Exploring Trend Beauty Industry",
  "footer.linkTitle4": "Trend series products",
  "footer.linkTitle1_label1": "Trend Chain",
  "footer.linkTitle1_label2": "AI skin texture testing",
  "footer.linkTitle1_label3": "AI Physical Fitness Test",
  "footer.linkTitle1_label4": "Meiye Distribution",
  "footer.linkTitle1_label5": "Customer Stories",
  "footer.linkTitle1_label6": "free trial",
  "footer.linkTitle2_label1": "Medical beauty institutions",
  "footer.linkTitle2_label2": "cosmetics",
  "footer.linkTitle2_label3": "postnatal care",
  "footer.linkTitle2_label4": "Traditional Chinese Medicine Physiotherapy",
  "footer.linkTitle2_label5": "Dance fitness",
  "footer.linkTitle2_label6": "Dance fitness",
  "footer.linkTitle3_label1": "About us",
  "footer.linkTitle3_label2": "News Center",
  "footer.linkTitle4_label1": "TRENDCLOUD",
  "footer.linkTitle4_label2": "Trend Beauty Industry",
  "footer.linkTitle4_label3": "Trend Intelligence",
  "footer.phnoe_msg": "Please enter your phone number",
  "footer.phnoe_msg2": "Please enter an 11 digit phone number in the correct format",
  "footer.subSuccess": "Submitted successfully",
  "footer.sunFail": "Submission failed",

  "home.f3_box_title": "One set of software to address all business needs",
  "home.f3_box_text": "Meiye Chain Online and Offline Integrated Management Solution",
  "home.f4_box_title": "Connecting all elements of beauty brand management",
  "home.f4_box_text": "Comprehensively and Deeply Solve the Difficulties of Brand Management in Meiye",
  "home.experienceNow": "Free demo",
  "home.f7_box_title": "Black technology's tool for attracting customers",
  "home.f7_box_text": "Visual AI technology empowers the health and beauty industries",
  "home.f6_box_title": "They all choose TRENDCLOUD",
  "home.f6_box_text": "Learn more cases > ",
  "home.f8_box_title": "Not only providing software, but also intelligent upgrading of hardware",
  "home.f8_box_text": "Combining software and hardware to accelerate digital operation and management of Meiye stores",
  "home.f5_box_title1": "Why choose",
  "home.f5_box_title2": "TRENDCLOUD",
  "home.f5_box_text": "Deepen business details and empower Meiye's business model operation with technology",
  "home.learnMore": "Learn more >",
  "home.f9_box_title": "The latest trends in the trend beauty industry",
  "home.more": "more",
  "home.noArticleList": "There are currently no relevant articles available",
  "home.bannerData_title1": "Trend Beauty Industry",
  "home.bannerData_subtitle1": "Meiye Chain's full stack digital management solution",
  "home.bannerData_content1_1":
    "Chain store management+multi touch private domain operation+AI IoT empowerment,",
  "home.bannerData_content1_2": "One stop solution to the operation and management of Meiye chain stores",
  "home.bannerData_button1": "try now",
  "home.bannerData_title2": "For beauty industry chain",
  "home.bannerData_subtitle2": "Has helped more than 1,000 beauty industry stores to achieve digital transformation and rapid profitability",
  "home.bannerData_button2": "Detail",
  "home.dataF2_title1": "Mini program",
  "home.dataF2_content1": "Reach customers",
  "home.dataF2_title2": "CHAIN CONTRO",
  "home.dataF2_content2": "Manage chain stores",
  "home.dataF2_title3": "Distribution",
  "home.dataF2_content3": "Everyone participates in the sale",
  "home.dataF2_title4": "Meiye AIOT",
  "home.dataF2_content4": "Black technology beauty industry",
  "home.f8_wrap_h1": "Skin tester",
  "home.f8_wrap_p1":
    "The skin quality testing API is embedded in the skin tester to interpret the skin condition, recommend care plans, and increase customers' willingness to pay.",
  "home.f8_wrap_h2": "Desk cash register",
  "home.f8_wrap_p2": "High performance cash register terminal/professional chip module for operation/stable, smooth, and scalable",
  "home.f8_wrap_h3": "Skin care warehouse",
  "home.f8_wrap_p3":
    "Networking of devices such as whitening warehouses/whitening warehouses/physical therapy machines/ultraviolet light therapy warehouses makes operations more intelligent and convenient",
  "home.f8_wrap_h4": "Massage device",
  "home.f8_wrap_p4": "Connect massage machines/mask lights/UV disinfection vehicle equipment to achieve equipment networking",
  "home.f8_wrap_h5": "receipt printer",
  "home.f8_wrap_p5": "High performance precision thermal printing head supports massive order printing, saving money and effort in operation and maintenance",
  "home.f8_wrap_h6": "Fitness testing robot",
  "home.f8_wrap_p6":
    "Body posture detection robot/traditional Chinese medicine physique robot, suitable for various scenarios, black technology contacts bring a continuous flow of customers to the store",
  "home.dataF4_title1": "Appointment scheduling",
  "home.dataF4_content1": "Convenient experience and intelligent management",
  "home.dataF4_title2": "Online booking:",
  "home.dataF4_content2": "Customers make self-service appointments online, technicians have a satisfactory experience, and the arrival rate is high",
  "home.dataF4_title3": "Appointment board",
  "home.dataF4_title4": "scheduling",
  "home.dataF4_title5": "Room management",
  "home.dataF4_title6": "Customer acquisition",
  "home.dataF4_content6": "Online and offline multi channel customer acquisition",
  "home.dataF4_title7": "Mini program mall",
  "home.dataF4_content7": "Super traffic entry for we chat user traffic",
  "home.dataF4_title8": "Newcomer coupons / Experience vouchers",
  "home.dataF4_title9": "AI skin testing / physical examination",
  "home.dataF4_title10": "Contents planting",
  "home.dataF4_title11": "Deal conversion",
  "home.dataF4_content11": "Activate consumption and improve performance",
  "home.dataF4_title12": "Flash Kill:",
  "home.dataF4_content12": "Limited time and quantity flash sales, triggering impulsive consumption",
  "home.dataF4_title13": "Experience price",
  "home.dataF4_title14": "Limited time coupon",
  "home.dataF4_title15": "Event lottery",
  "home.dataF4_title16": "Retained repurchase",
  "home.dataF4_content16": "Lock in customers and retain customers, with steady growth in performance",
  "home.dataF4_title17": "Stored-value card, medical card, package card:",
  "home.dataF4_content17": "Enrich card item design schemes to attract customers to apply for and recharge cards",
  "home.dataF4_title18": "Integral system",
  "home.dataF4_title19": "RFM intelligent analysis",
  "home.dataF4_title20": "Membership Rights/Relationships",
  "home.dataF4_title21": "Distribution fission",
  "home.dataF4_content21": "Promoting performance growth through nationwide sales promotion",
  "home.dataF4_title22": "Sharing voucher:",
  "home.dataF4_content22": "Share good gifts and tap into the connections behind old customers through WeChat coupons",
  "home.dataF4_title23": "Piecing together a group",
  "home.dataF4_title24": "Bargaining assistance",
  "home.dataF4_title25": "Distribution",
  "home.dataF4_title26": "Chain operation",
  "home.dataF4_content26": "Unified management of multiple stores, standardized rapid brand expansion",
  "home.dataF4_title27": "Permission settings:",
  "home.dataF4_content27": "Super traffic entry for WeChat user traffic",
  "home.dataF4_title28": "resource sharing",
  "home.dataF4_title29": "Marketing plan",
  "home.dataF4_title30": "Financial monitoring",
  "home.dataF4_title31": "Data report",
  "home.dataF4_content31": "Multidimensional business data analysis, empowering refined operations",
  "home.dataF4_title32": "Member Report:",
  "home.dataF4_content32": "Membership card consumption, membership statistics, and membership profile are clear at a glance",
  "home.dataF4_title33": "earning report",
  "home.dataF4_title34": "Chain Report",
  "home.dataF4_title35": "Decision report",
  "home.dataF5_title1": "Deeply understand the core of Meiye brand management",
  "home.dataF5_content1":
    "Connecting all elements of beauty brand management, from membership, store management, marketing, and supply chain integrated solutions, truly a one-stop solution",
  "home.dataF5_title2": "Massive customer accumulation, continuous optimization and upgrading",
  "home.dataF5_content2":
    "Massive customer accumulation, serving customers covering beauty industries such as makeup, beauty hospitals, traditional Chinese medicine diagnosis and treatment, dental clinics, dance and fitness, etc",
  "home.dataF5_title3": "Private domain operation empowers store growth and customer acquisition",
  "home.dataF5_content3":
    "A fully functional Meiye mini program that empowers brands to handle expansion, retention, lock up, and upgrade, links customers, and achieves explosive traffic growth and monetization",
  "home.dataF5_title4": "17 years of industry experience, strong brand guarantee",
  "home.dataF5_content4":
    "A strong and stable product research and development team, dedicated to accompanying, supporting business customization, and meeting personalized needs for various functions and scenarios",

  "home.dataF6_list1_obj1_name1": "Sunshine Medical Beauty",
  "home.dataF6_list1_obj1_content1":
    "National 5A Aesthetic medicine institutions, high-end medical beauty brands in South China, and leading enterprises in medical beauty industry",
  "home.dataF6_list1_obj1_content1_2":
    " 'Through digital means, we will continue to create brand digital retail formats through three major methods: community grass planting, medical beauty forums, and smart supply chain' ",
  "home.dataF6_list1_obj1_iconText1": "Supply Chain",
  "home.dataF6_list1_obj1_iconText1_2": "Grass planting community",
  "home.dataF6_list1_obj1_iconText1_3": "Medical Beauty Forum",

  "home.dataF7_list1_obj1_name1": "Huabang Pharmaceutical",
  "home.dataF7_list1_obj1_content1": "A leading enterprise in the field of clinical skin medication and skin health in China, with the most complete skin drug product line in the country",
  "home.dataF7_list1_obj1_content1_2": ' “Seize the new growth point of "AI+dermatology", and based on the advantages of trend cloud AI algorithm, achieve the perfect integration of AI technology and business logic” ',
  "home.dataF7_list1_obj1_iconText1": "AI skin testing",
  "home.dataF7_list1_obj1_iconText1_2": "Beautiful Mall",
  "home.dataF7_list1_obj1_iconText1_3": "Skin profile",

  "home.dataF6_list1_obj1_name2": "Han Fei Yi Mei",
  "home.dataF6_list1_obj1_content2":
    "Listed as a top tier plastic surgery and beauty hospital in Guangdong, achieving large-scale operation of a chain of brands in 4 cities and 6 hospitals nationwide",
  "home.dataF6_list1_obj1_content2_2":
    " 'Deeply focus on the digital layout of enterprises, build a vertical e-commerce ecological platform for health and beauty, and achieve new breakthroughs in brand performance growth.' ",
  "home.dataF6_list1_obj1_iconText2": "Chain+micro store",
  "home.dataF6_list1_obj1_iconText2_2": "Service Appointment",
  "home.dataF6_list1_obj1_iconText2_3": "Live online",

  "home.dataF6_list1_obj1_name3": "Face washing cat",
  "home.dataF6_list1_obj1_content3":
    "Innovatively creating a 'light beauty and facial wash bar', focusing on facial care services, with over 500 stores nationwide",
  "home.dataF6_list1_obj1_content3_2":
    " 'Redefine the business scenario with 'light beauty+new retail', open up online and offline channels, and help brands achieve rapid investment and franchise' ",
  "home.dataF6_list1_obj1_iconText3": "Online shopping mall",
  "home.dataF6_list1_obj1_iconText3_2": "Member distribution",
  "home.dataF6_list1_obj1_iconText3_3": "Agent management",

  "home.dataF6_list1_obj1_name4": "Foraging for light",
  "home.dataF6_list1_obj1_content4":
    "Tencent, Xiaomi, ZhenFund and other capital strategies have become shareholders, and the top new consumer brands of domestic beauty instruments",
  "home.dataF6_list1_obj1_content4_2":
    ' By upgrading the product experience through AI technology, breaking the traditional marketing dilemma, the monthly sales continue to grow, creating a performance myth of over 300 million.  ',
  "home.dataF6_list1_obj1_iconText4": "AI skin testing",
  "home.dataF6_list1_obj1_iconText4_2": "Product recommendations",
  "home.dataF6_list1_obj1_iconText4_3": "Skin Archives",

  "home.dataF6_list1_obj2_name1": "Yanglan",
  "home.dataF6_list1_obj2_content1":
    "Swiss technology skincare brand, long-term partnership with Clinique La Prairie (CLP), leading the global anti-aging field with technology",
  "home.dataF6_list1_obj2_content1_2":
    " By using 'distribution' to achieve large-scale coverage and high-precision locking, we can help brands quickly reach customers and ignite new growth in brand performance ",
  "home.dataF6_list1_obj2_iconText1": "APP Mall",
  "home.dataF6_list1_obj2_iconText1_2": "Business School",
  "home.dataF6_list1_obj2_iconText1_3": "Two-level distribution",

  "home.dataF6_list1_obj2_name2": "Meizhonglian",
  "home.dataF6_list1_obj2_content2":
    "China's first beauty industry shared procurement platform led by Ma Ya, President of the Beauty Expo, and jointly built by more than 30 enterprises",
  "home.dataF6_list1_obj2_content2_2":
    " Build a third-party supply and demand formula service platform, share supply chain resources, improve overall efficiency, and unleash the strong aggregation power of the beauty industry“ ",
  "home.dataF6_list1_obj2_iconText2": "Supply Chain System",
  "home.dataF6_list1_obj2_iconText2_2": "Raw Material Mall",
  "home.dataF6_list1_obj2_iconText2_3": "Recommended formula",

  "home.dataF6_list1_obj2_name3": "Meijian Collection",
  "home.dataF6_list1_obj2_content3":
    "Biotechnology skincare brand, the group takes biotechnology as the foundation and lays out the big health ecological industry chain",
  "home.dataF6_list1_obj2_content3_2":
    " 'Build a smart beauty chain franchise mall platform with a new digital engine, and build a digital and standardized service application scenario' ",
  "home.dataF6_list1_obj2_iconText3": "Chain franchise",
  "home.dataF6_list1_obj2_iconText3_2": "Service Appointment",
  "home.dataF6_list1_obj2_iconText3_3": "Member distribution",

  "home.dataF6_list1_obj2_name4": "Meilikang",
  "home.dataF6_list1_obj2_content4":
    "Large high-tech optical equipment enterprise, a national leader in the field of optical beauty, with main products including health whitening cabins, photodynamic therapy cabins, and sunbathing machines (beauty black machines)",
  "home.dataF6_list1_obj2_content4_2":
    " 'Device networking and hardware intelligent upgrades help brands create a technology experience that is more easily perceived by users' ",
  "home.dataF6_list1_obj2_iconText4": "AI skin testing",
  "home.dataF6_list1_obj2_iconText4_2": "AI Physical Fitness Test",
  "home.dataF6_list1_obj2_iconText4_3": "Device networking/control",

  "home.dataF6_list1_obj3_name1": "moist",
  "home.dataF6_list1_obj3_content1":
    "Provide high-end beauty services for freckle and wrinkle removal, focusing on technology skincare. Currently, there are 200 offline stores",
  "home.dataF6_list1_obj3_content1_2":
    " 'Deeply focus on brand needs, create a private domain operation matrix and one-stop solution for store pooling, empowering offline stores' ",
  "home.dataF6_list1_obj3_iconText1": "AI detection",
  "home.dataF6_list1_obj3_iconText1_2": "reservation service",
  "home.dataF6_list1_obj3_iconText1_3": "Store Pool",

  "home.dataF7_title1": "Skin texture testing",
  "home.dataF7_content1":
    "Collaborate with the camera to analyze the skin condition from multiple perspectives such as skin color, skin age, and pore smoothness, and based on the analysis results, intelligently recommend one-on-one beauty plans",
  "home.dataF7_title2": "Physical examination",
  "home.dataF7_content2":
    "Intelligent robots achieve the four diagnostic methods of traditional Chinese medicine: observation, hearing, inquiry, and cutting, quickly identifying traditional Chinese medicine constitution, and recommending health preservation plans",
  "home.dataF7_title3": "Physical examination",
  "home.dataF7_content3":
    "Obtain the coordinates of the core key points of the human body, and quickly provide users with professional posture reports by calculating posture data such as the shoulders, spine, pelvis, and legs",
  "home.articleCate_name1": "Product dynamics",
  "home.articleCate_name2": "Signing dynamics",
  "home.articleCate_name3": "Customer stories",
  "home.articleCate_label1": "Focusing on innovation to the extreme",
  "home.articleCate_label2": "Cooperation and win-win development",
  "home.articleCate_label3": "Quality reputation trend",

  "article.currentLocation": "Current location:",
  "article.articlList": "Article List",
  "article.articlPlaceholder": "Please enter the article title",
  "article.readFullArticle": "Read more",
  "article.nan": "None",
  "article.relatedArticl": "Related articles",

  // huanggengsheng
  // 美业小程序
  "MiniProgram.bancontent1": "AI empowering private domain monetization tool, deep fission to reach customers, intelligent marketing to handle expansion, retention, lock, and promotion",
  "MiniProgram.bancontent2": "Efficient management | Efficient customer acquisition | Efficient monetization",
  "MiniProgram.box1_content1": "Black technology is a powerful tool for expanding customers, efficiently draining and splitting",
  "MiniProgram.box1_content2": "Based on visual AI technology and combined with cameras, the skin condition is analyzed from multiple perspectives such as skin color, skin age, pores, and smoothness. Based on the analysis results, products are intelligently recommended to build a commercial closed-loop from traffic to monetization.",
  "MiniProgram.box2_content1": "AI physique detection algorithm empowers the smart upgrade of the big health industry",
  "MiniProgram.box2_content2": "Provide partners with online docking services for tongue diagnosis and skin testing through APIs or H5 interfaces, and intelligently recommend product conditioning solutions. The application scenarios include pharmacies, communities, nursing homes, tourism, hospitals, and maternity centers.",
  "MiniProgram.box3_content1": "Expert/service/product online reservation, reasonable optimization of resource utilization",
  "MiniProgram.box3_content2": "Merchants can publish bookable beauty projects in the mall, guiding customers to make online self-service appointments, reasonably guiding store passenger flow, improving workstation utilization, optimizing employee resource allocation, and eliminating the need for customers to queue up offline, resulting in a higher sense of experience.",
  "MiniProgram.box4_title": "LBS positioning",
  "MiniProgram.box4_content1": "Based on LBS positioning, accurately attract nearby customers",
  "MiniProgram.box4_content2": "Based on LBS positioning, increase store exposure rate, radiate 5 kilometers of stores, and improve customer flow; Help users quickly discover nearby stores, help merchants accurately attract nearby customers, and combine location information to quickly respond to services and products, improving user experience.",
  "MiniProgram.box5_content1": "Interactive beauty industry community sharing ecosystem, achieving closed-loop commercial monetization in the private domain",
  "MiniProgram.box5_content2": "Short videos/images/text form notes reach customers, and through embedded product links, drive consumption conversion from content to create a monetization loop; We have provided a new marketing and customer expansion system for beauty salons, physical therapy centers, gyms, cosmetics, pets, and other beauty businesses.",
  "MiniProgram.box6_title": "Marketplace monetization",
  "MiniProgram.box6_content1": "Open Meiye stores to customers' phones and seize billions of traffic dividends",
  "MiniProgram.box6_content2": "Mini program online shopping mall, connecting online and offline businesses, playing with private domain traffic, and helping Meiye stores transform and upgrade to smart new retail; The support of AI black technology Buff such as AI skin testing, AI physique, and AI posture helps businesses quickly achieve dual growth in traffic and sales.",
  "MiniProgram.box7_title": "Mall drag and drop",
  "MiniProgram.box7_content1": "What you see is what you get, landing your business ideas faster",
  "MiniProgram.box7_content2": "Drag and drop style custom decoration, customization+privatization deployment, fast response, agile iteration, instant and on-demand changes, helping beauty merchants quickly build beauty mini program shopping malls and easily achieve different business scenarios.",
    // 美业连锁
    "MeiyeChain.bancontent1": "Fully support multiple chain business models and orderly franchise expansion",
    "MeiyeChain.bancontent2": "Direct franchise chain",
  "MeiyeChain.box1_title": "Manage Chains",
  "MeiyeChain.box1_content1": "Support multiple chain operation modes, and complete direct franchise and mixed operation",
  "MeiyeChain.box1_content2": "The headquarters has customized hierarchical permission management, supporting configuration by headquarters, province, city, district, store, and other regions to meet the business needs of various chain formats such as direct operation, franchise, and freedom.",
  "MeiyeChain.box2_title": "Store chain",
  "MeiyeChain.box2_content1": "Unified management of multiple stores, standardized operation, and accelerated brand expansion",
  "MeiyeChain.box2_content2": "Each store has an online shopping mall, and the headquarters can quickly empower mature business models to each store for online marketing, trading, or attracting customers to the store.",
  "MeiyeChain.box3_title": "Resource chain",
  "MeiyeChain.box2_content1": "Store data interconnection and mutual sharing, comparative analysis and collaborative development",
  "MeiyeChain.box3_content2": "Products, inventory, membership, consumption, and human resources are interconnected, with centralized management by the headquarters and shared use by multiple stores, achieving resource sharing and complementary advantages, and comprehensively improving the overall operational efficiency and brand influence of the store.",
  "MeiyeChain.box4_title": "Marketing Chain",
  "MeiyeChain.box4_content1": "Global planning of marketing plans, unified promotion and multi store linkage",
  "MeiyeChain.box4_content2": "The marketing gameplay set by the system, such as teaming, flash killing, bargaining, coupons, stored value, points, full reduction and full gift, and Tuoke mini games, can be synchronized to every store to help them win customers and attract new customers.",
  "MeiyeChain.box5_title": "Membership Chain",
  "MeiyeChain.box5_content1": "Support store member sharing to maximize economic benefits",
  "MeiyeChain.box5_content2": "The headquarters manages member information uniformly, and all stores can share all customer resources of the headquarters. It supports member cross store consumption, and the system automatically identifies member identities, counts and writes off, facilitating the clearance and settlement of each store.",
  "MeiyeChain.box6_title": "Data concatenation",
  "MeiyeChain.box6_content1": "Operational data perspective analysis to assist managers in operational decision-making",
  "MeiyeChain.box6_content2": "The operation data of each store is interconnected and shared, with a visual data dashboard that facilitates the headquarters to view and compare with each other with one click. It comprehensively controls the core operation data, makes scientific management decisions, and improves the efficiency of store operations",
  //美业分销
  "MeiyeDistribution.bantitle": "Trend Beauty Distribution System",
  "MeiyeDistribution.bancontent1": "Simple, efficient, and fast start to volume, everyone brings goods, which really increases sales volume",
  "MeiyeDistribution.bancontent2": "Multi role | multi-level | multimodal distribution, helping merchants build distribution channels and expand sales channels",
  "MeiyeDistribution.box1_title": "distribution model",
  "MeiyeDistribution.box1_content1": "Quickly build your exclusive distribution system from 0 to 1",
  "MeiyeDistribution.box2_title": "Featured distribution model",
  "MeiyeDistribution.box2_content1": "Not only providing a system, but also providing a complete set of distribution solutions",
  "MeiyeDistribution.box2_content2": "Support the setting of distribution levels, level weights, different identity and role rights, as well as the threshold of 'growth value' obtained from user behavior in different dimensions, to motivate users/agents to actively distribute and scientifically cultivate fan stickiness.",
  "MeiyeDistribution.box3_title": "Distribution settlement",
  "MeiyeDistribution.box3_content1": "Third party authoritative financial clearing system for air accounting",
  "MeiyeDistribution.box3_content2": "The system automatically divides accounts, with visible pen and pen returns and clear accounts. One person can also manage a thousand person distribution team; The income of all parties is accounted for proportionally, effectively solving the problem of sharing additional expenses such as handling fees, value-added tax, and corporate income tax.",
  "MeiyeDistribution.box4_title": "Distribution tools",
  "MeiyeDistribution.box4_content1": "A multifunctional distribution system that helps you gather partners with benefits and rules",
  "MeiyeDistribution.box4_content2": "Share coupons, task cards, bargaining assistance, team building, posters, and 100+distribution tools can be combined and matched to meet the needs of different models, saving millions of promotion expenses and making distribution simple and practical.",
  "MeiyeDistribution.box5_title": "distribution management",
  "MeiyeDistribution.box5_content1": "Full process tracking management, focusing on every aspect of distribution",
  "MeiyeDistribution.box5_content2": "Corresponding agency commission rules can be formulated based on business needs, and hierarchical relationships can be recorded through the relationship chain to track the entire distribution process; Visual distribution data dashboard, monitoring and management anytime, anywhere.",

  // 关于我们
  "AboutUs.Facingthe": "Facing global beauty industry and big health enterprises",
  "AboutUs.Provideaone-stop": "Provide a one-stop digital management and marketing solution",
  "AboutUs.Freeexperience": "Free experience",
  "AboutUs.TrendMeiye": "Trend Meiye and You Create Future Trends Together",
  "AboutUs.TrendBeautyprovides": "Trend Beauty provides a one-stop operation and management solution specifically for the beauty industry (medical beauty, traditional Chinese medicine therapy, skin management, dance fitness, dental clinics, postpartum care, foot bath spa, body and weight loss, nail and ciliary beauty, beauty and hairdressing...). From the customer expansion mini program to chain store management, to the Meiye supply chain system, as well as the artificial intelligence+AI algorithm developed by Trend Cloud, we use skin recognition and physique recognition as the entry points to solve the pain points and needs of the industry's customer attraction and continuous monetization. Combined with the Meiye Internet of Things solution, we comprehensively solve the operational needs of Meiye brand merchants.",
  "AboutUs.wehaveaccumulated": "As of now, we have accumulated successful service experience for thousands of corporate clients, including Sunshine Medical Beauty, Hanfei Medical Beauty, Nariel, Infinite, Facial Cat, Miguang, Huabang Pharmaceutical, Proting, RARESEE, Lihai Pharmaceutical, Meiling Light Medical Beauty, Xianmei Ji, Laihe Sibao (Singapore), Runze Meiye, Pineapple Tree, Meilikang, Yanglan (Switzerland), Fenle, Huaya Optoelectronics, and others.",
  "AboutUs.GlobalCustomers": "Global Customers",
  "AboutUs.managementefficiency": "Improved management efficiency",
  "AboutUs.acquisitionefficiency": "Improved customer acquisition efficiency",
  "AboutUs.operationalefficiency": "Improved operational efficiency",
  "AboutUs.Navigationteam": "Navigation team",
  "AboutUs.HuangLiangxing": "Huang Liangxing",
  "AboutUs.GeneralManager": "General Manager of Malaysia (Retail) Listed Company in China",
  "AboutUs.ListedCompanies": "General Manager of Southeast Asian (Logistics) Listed Companies in China",
  "AboutUs.experienceoverdue": "25 years of marketing experience overdue",
  "AboutUs.DirectorofOperations": "Director of Operations, Trend Beauty International Business Development Center",
  "AboutUs.BusinessSchool": "Supervisor and Founder of Global Beauty Health Business School",
  "AboutUs.Chairman": "Chairman of the Hong Kong Beauty Association",
  "AboutUs.Senior": "Senior Entrepreneurs and Training Mentors",
  "AboutUs.BeautyIndustry": "Trend Beauty Industry Operation Strategy Expert",
  "AboutUs.JiaChao": "Jia Chao",
  "AboutUs.ChiefTraditional": "Chief Traditional Chinese Medicine Practitioner/Medical Doctor/Master's Supervisor",
  "AboutUs.Academic": "Academic inheritor of Jia Lihui's acupoint therapy, a national intangible cultural heritage",
  "AboutUs.Professor": "Professor Chen Jichang, the academic inheritor of renowned traditional Chinese medicine orthopedics and traumatology",
  "AboutUs.Technical": "Technical experts in the field of trend beauty and big health",
  "AboutUs.WenGuihua": "Wen Guihua",
  "AboutUs.Technology": "Professor and doctoral supervisor of South China University of Technology",
  "AboutUs.Director": "Director of Machine Learning and Data Mining Laboratory at South China University of Technology",
  "AboutUs.Leader": "Leader in the application of AI physique recognition robots in China",
  "AboutUs.Member": "Member of the Trend Beauty AI Intelligent Product Development Expert Group",
  "AboutUs.explorationprocess": "exploration process",
  "AboutUs.foundedTrend": "Mr. Wang Zhenjun founded Trend Cloud and was approved as a national software enterprise;",
  "AboutUs.Awarded": "Awarded the Best Application Service Provider and Best Application Developer in China;",
  "AboutUs.Thesupplychain": "The supply chain platform 'Duoshang Network' has been launched, integrating 100000 supplier resources and laying out 300000 online and offline distributor channels",
  "AboutUs.Unitedwith": "United with the Artificial Intelligence Laboratory of South China University of Technology to launch a skin texture detection system; Preliminary incubation of trend beauty industry",
  "AboutUs.SouthChina": "United with South China University of Technology to launch artificial intelligence posture detection system and traditional Chinese medicine constitution detection system",
  "AboutUs.TheArtificialIntelligence": "The 'Artificial Intelligence Application Research and Development Base' awarded as the 'Guangdong Artificial Intelligence Traditional Chinese Medicine Engineering Technology Research Center'",
  "AboutUs.TrendBeauty": "'Trend Beauty 'SaaS products leverage artificial intelligence algorithms to harvest pan American markets such as medical beauty, big health, and traditional Chinese medicine diagnosis and treatment",
  "AboutUs.TrendCloud": "Trend Cloud (Hong Kong) (Trend Cloud International Business Development Center) is officially operational, laying out its presence in the international market and providing more professional services to global beauty industry customers",
  "AboutUs.Embark": "Embark on a journey of exploration",
  "AboutUs.ContactUs": "Contact Us",
  "AboutUs.Guangzhou": "Guangzhou | Headquarters",
  "AboutUs.ZhongshanAvenue": "89 Zhongshan Avenue West, Tianhe District, Guangzhou",
  "AboutUs.WestLadder": "5th Floor, West Ladder, Building B, Huajing Software Park",
  "AboutUs.HongKong": "Hong Kong",
  "AboutUs.Foshan": "Foshan",
  "AboutUs.FenjiangMiddle": "215 Fenjiang Middle Road, Chancheng District, Foshan City",
  "AboutUs.Room": "Room 1008, Entrepreneurship Building",
  "AboutUs.phone": "phone",
  "AboutUs.mailbox": "mailbox",
  
   // 客户案例
   "Customer.Advanced": "Advanced Enterprise Practice Cases",
   "Customer.businessgrowth": "New ways of business growth in the beauty industry",
   "Customer.Scheme": "Scheme free collection",
   "Customer.Deepening": "Deepening into core business scenarios, Trend Cloud assists Nariel brand stores in providing one-stop solutions such as drainage, revenue growth, and management",
   "Customer.Narielhas":"Nariel has chosen to collaborate with Trend Cloud, aiming to integrate business and marketing scenarios through AI black technology empowerment and digital chain operation management, and build a one-stop beauty and health care center that covers 'life beauty+medical beauty+big health', helping the brand achieve comprehensive AI+digital empowerment and achieve global growth.",
   "Customer.completestory": "Read the complete story",
   "Customer.Meiye": "Meiye",
   "Customer.SeekingLight": "Seeking Light: A Secret Weapon for Monthly Sales Continuously Breaking Billions", 
   "Customer.SunshineMedical": "Sunshine Medical Beauty: A Digital Upgrade Route for 5A Level Medical Beauty Hospitals", 
   "Customer.HuabangPharmaceuticals": "Huabang Pharmaceutical: A Double Sword Combination of AI and Skin Clinical Pharmaceutical Enterprises", 
   "Customer.ShampooCat": "Shampoo Cat: Light medical beauty brands need to use this investment strategy", 
   "Customer.Unlimited": "Unlimited: One action makes direct salespeople more professional and stable", 
   "Customer.Proting": "Proting: Is AI physical fitness testing introduced in the physical examination center?!", 
   "Customer.LaiheSibao": "Laihe Sibao: Singapore Laihe Sibao, AI Upgrade Strategy for Overseas Traditional Chinese Medicine Clinics", 
   "Customer.Zeran": "Zeran: The Secret to a 4-person Small Team's Performance Growth of 100w", 
   "Customer.Meilikang": "Meilikang: Meiye IoT provides customers with a high-quality service experience", 
   "Customer.RARESEE": "RARESEE: Atomization beauty instrument+AI skin measurement creates a stable private domain traffic pool", 
   "Customer.ChenNongfu": "Chen Nongfu: Overcoming 300+franchisees with one move", 
   "Customer.OuTinger": "Ou Tinger: With a surge of over 300w in performance and a repurchase rate of over 90%", 
   "Customer.Fenle": "Fenle: Benchmarking BabyCenter to revitalize pregnant mother users", 
   "Customer.PineappleTree": "Pineapple Tree: Creativity of the 800+Mother and Child Toy Experience Hall", 
   "Customer.Youkeyhui": "Youkeyhui: How to do a good job in the business of users over 60 years old", 
   "Customer.Huamei": "Huamei: Guangzhou Huamei and Trend Cloud have reached cooperation to jointly build a new highland of [intelligent traditional Chinese medicine beauty and care]",
   "Customer.Theimagecomes": "*The image comes from customer provided or publicly available information/customer website. If there is any infringement, please contact to delete it", 
   "Customer.Browsecustomer": "Browse customer stories", 
};

export default en;
