<template>
  <div>
    <el-carousel indicator-position="outside" height="424px">
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <div
          class="bg-img"
          :style="{ 'background-image': `url(${item.bgimg})` }"
        >
          <div class="flex bg-top">
            <el-image :src="item.iconimg" fit="contain" v-if="item.iconimg"></el-image>
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="bg-detail">
            {{ item.detail }}
          </div>
          <div class="bg-btn" @click="CaseDetails(item.url)">案例详情</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "OfficialMeiyewebCarousel",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  mounted() {},

 methods: {
  CaseDetails(url) {
     console.log(url);
     if(url){
      window.open(url);
     }
  }
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-carousel__indicator--horizontal {
  padding: 45px 4px;
}
/deep/ .el-carousel__button {
  height: 6px;
}
/deep/ .el-carousel__indicator.is-active button {
  background: #04cb94;
}
.bg-img {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 424px;
  overflow: hidden;
  padding: 50px 0px 108px 101px;
}
.bg-top {
  // margin: 75px 0px 0px 101px;
}
.flex {
  display: flex;
  align-items: center;
}
.title {
  font-size: 42px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-left: 11px;
}
.bg-detail {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 20px;
  width: 565px;
  // height: 81px;
  line-height: 30px;
  margin-bottom: 20px;
}
.bg-btn {
  width: 155px;
  height: 49px;
  background: #04cb94;
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 49px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
