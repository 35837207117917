<template>
  <div class="Miniprogram">
    <!-- 头部 -->
    <common-nav @clickBtn="toFooterWrap" />
    <!-- 广告 -->
    <custom-banner
      :data="bannerData"
      :mode="2"
      layout="left"
      theme="#04CB94"
      text_color="black"
      :border-radius="50"
      :is-cns="isCns"
      @clickBtn="toFooterWrap"
    ></custom-banner>
    <!-- 美业小程序下面的box -->
    <div class="minbox meiyebox">
      <div class="minbox-boxd container">
        <div class="title">{{$t('MeiyeDistribution.box1_title')}}</div>
        <div class="desc">{{$t('MeiyeDistribution.box1_content1')}}</div>
      </div>
      <div class="Miniprogram-box-pontimg" @click="toFooterWrap">
        <img
          src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/1655393026849378304/material/5996b187-d510-4435-8a1e-5ef4e93b6673.png"
          alt=""
          class="imgs"
        />
      </div>
    </div>
   
      <div class="Miniprogram-box container">
        <div class="Miniprogram-box-contentbox">
          <div class="title">{{$t('MeiyeDistribution.box2_title')}}</div>
          <div class="desc">{{$t('MeiyeDistribution.box2_content1')}}</div>
          <div class="heng"></div>
          <div class="content">
            {{$t('MeiyeDistribution.box2_content2')}}
          </div>
          <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
        </div>
        <div class="Miniprogram-box-pontimg">
          <img
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/bbe9e12d-e970-4630-9901-f55a2bed6f29.png"
            alt=""
            class="img1"
          />
        </div>
      </div>
     <div class="minbox">
    <div class="Miniprogram-box container">
      <div class="Miniprogram-box-pontimg">
        <img
          src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/e9067cfe-d122-4a73-865f-38ffe5276940.png"
          alt=""
          class="img2"
        />
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MeiyeDistribution.box3_title')}}</div>
        <div class="desc">{{$t('MeiyeDistribution.box3_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MeiyeDistribution.box3_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
     </div>
   
      <div class="Miniprogram-box container">
        <div class="Miniprogram-box-contentbox">
          <div class="title">{{$t('MeiyeDistribution.box4_title')}}</div>
          <div class="desc">{{$t('MeiyeDistribution.box4_content1')}}</div>
          <div class="heng"></div>
          <div class="content">
            {{$t('MeiyeDistribution.box4_content2')}}
          </div>
          <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
        </div>
        <div class="Miniprogram-box-pontimg">
          <img
            src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/34e2d466-e8d3-4524-8aab-5d60cf40f192.png"
            alt=""
            class="img3"
          />
        </div>
      </div>
    <div class="minbox">
    <div class="Miniprogram-box container">
      <div class="Miniprogram-box-pontimg">
        <img
          src="https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/d6b31609-b9de-4fe3-97be-b12a6d9e91ee.png"
          alt=""
          class="img4"
        />
      </div>
      <div class="Miniprogram-box-contentbox minleft">
        <div class="title">{{$t('MeiyeDistribution.box5_title')}}</div>
        <div class="desc">{{$t('MeiyeDistribution.box5_content1')}}</div>
        <div class="heng"></div>
        <div class="content">
          {{$t('MeiyeDistribution.box5_content2')}}
        </div>
        <div class="btns" @click="toFooterWrap">{{$t('nav.tryFree')}}</div>
      </div>
    </div>
    </div>

    <!-- 回到顶部 -->
    <backTOP />

    <!-- 页脚 -->
    <div id="footer">
      <custom-footer />
    </div>
  </div>
</template>

<script>
// 顶部导航栏组件
import commonNav from "@/components/commonNav/index.vue";
// 广告图组件
import customBanner from "@/components/customBanner/index.vue";
// 页脚
import customFooter from "@/components/customFooter/index.vue";
export default {
  name: "OfficialMeiyewebIndex",
  components: {
    commonNav,
    customBanner,
    customFooter,
  },
  data() {
    return {
      isCns: false,
      bannerData: [
        {
          title: this.$t('MeiyeDistribution.bantitle'),
          // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
          banner:
            "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/aa6dad0a-48c3-4a30-9e79-e3e0d8a9b49e.png",
          subtitle: this.$t('MeiyeDistribution.bancontent1'),
          content: [
            this.$t('MeiyeDistribution.bancontent2'),
          ],
          button: this.$t("home.bannerData_button1"),
        },
        // {
        //   title: this.$t("home.bannerData_title2"),
        //   // banner: '__CDN__/assets/img/new_index/newCosmetology/banner.png',
        //   banner:
        //     "https://tpsite.oss-cn-hangzhou.aliyuncs.com/-1/mall/user/f5a5e209-faa4-483b-9fea-72b1c393dc22.png",
        //   subtitle: this.$t("home.bannerData_subtitle2"),
        //   content: [],
        //   button: this.$t("home.bannerData_button2"),
        // },
      ],
    };
  },

  mounted() {},

  methods: {
    /**
      * 锚点定位
      */
    toFooterWrap() {
      document.querySelector('#footer').scrollIntoView({
				behavior: "smooth"
		  });
    },
  },
};
</script>

<style lang="less" scoped>
.meiyebox{
    height: 889px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.minbox {
  width: 100%;
  background-color: #f9fafc;
  &-boxd {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 80px 0;
    .title {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .desc {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 11px;
    }
  }
}
.minleft {
  margin-left: 151px;
}
.Miniprogram {
  &-box {
    // height: 651px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0;
    &-contentbox {
      width: 782px;
      height: 555px;
      display: flex;
      padding-top: 120px;
      flex-direction: column;
      .title {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .desc {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 11px;
      }
      .heng {
        width: 60px;
        height: 6px;
        background: #04cb94;
        margin: 24px 0px 29px 0px;
      }
      .content {
        width: 512px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 36px;
      }
      .btns {
        width: 111px;
        height: 43px;
        background: #04cb94;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 43px;
        margin-top: 30px;
        text-align: center;
        cursor: pointer;
      }
    }
    &-pontimg {
      .img1 {
        width: 712px;
        height: 470px;
      }
      .img2 {
        width: 526px;
        height: 452px;
      }
      .img3 {
        width: 664px;
        height: 464px;
      }
      .img4 {
        width: 674px;
        height: 476px;
      }
      .imgs {
        width: 1542px;
        height: 578px;
        margin-left: 206px;
      }
    }
  }
}
</style>
