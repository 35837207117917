const zhhk = {
  "nav.home": "首頁",
  "nav.beautyIndustryMiniProgram": "美業小程序",
  "nav.beautyChain": "美業連鎖",
  "nav.beautyDistribution": "美業分銷",
  "nav.beautyAloT": "美業AloT",
  "nav.customerStories": "客戶案例",
  "nav.aboutUs": "關於我們",
  "nav.tryFree": "免費試用",

  "footer.part1_title1": "趨勢美業，美業生意增長專家",
  "footer.part1_text1": "專註為美業品牌商提供社交電商、小程序、裂變分銷係統、AI測膚算法、AI體質檢測算法、新零售、智慧門店、供應鏈的美業一體化解決方",
  "footer.withContactUs": "與我們聯係",
  "footer.part1_text2": "將有專人為你提供經營建議、解決方案、專業答疑等",
  "footer.phonePlaceholder": "請輸入",
  "footer.submitBtn": "預約回電",
  "footer.contactUsTitle": "聯係我們",
  "footer.consultation": "售前服務谘詢",
  "footer.contactCustomerService": "聯係客服",
  "footer.officialAccount": "趨勢雲公眾號",
  "footer.companyName": "廣州市趨勢信息技術有限公司",
  "footer.icp": "粵ICP備18106639號-1",
  "footer.linkTitle1": "熱門鏈接",
  "footer.linkTitle2": "解決方案",
  "footer.linkTitle3": "探索趨勢美業",
  "footer.linkTitle4": "趨勢係列產品",
  "footer.linkTitle1_label1": "趨勢連鎖",
  "footer.linkTitle1_label2": "AI膚質檢測",
  "footer.linkTitle1_label3": "AI體質檢測",
  "footer.linkTitle1_label4": "美業分銷",
  "footer.linkTitle1_label5": "客戶案例",
  "footer.linkTitle1_label6": "免費試用",
  "footer.linkTitle2_label1": "醫美機構",
  "footer.linkTitle2_label2": "化妝品",
  "footer.linkTitle2_label3": "產後護理",
  "footer.linkTitle2_label4": "中醫理療",
  "footer.linkTitle2_label5": "舞蹈健身",
  "footer.linkTitle2_label6": "舞蹈健身",
  "footer.linkTitle3_label1": "關於我們",
  "footer.linkTitle3_label2": "新聞中心",
  "footer.linkTitle4_label1": "趨勢雲",
  "footer.linkTitle4_label2": "趨勢美業",
  "footer.linkTitle4_label3": "趨勢智能",
  "footer.phnoe_msg": "請輸入手機號",
  "footer.phnoe_msg2": "請輸入11位正確格式的手機號",
  "footer.subSuccess": "提交成功",
  "footer.sunFail": "提交失敗",

  "home.f3_box_title": "一套軟件解決全部經營需要",
  "home.f3_box_text": "美業連鎖線上線下一體化經營解決方案",
  "home.f4_box_title": "連接一切美業品牌經營要素",
  "home.f4_box_text": "全方位深入解決美業品牌經營管理難題",
  "home.experienceNow": "立即體驗",
  "home.f7_box_title": "黑科技拓客引流神器",
  "home.f7_box_text": "視覺 AI 技術，賦能健康產業及美業",
  "home.f6_box_title": "他們都選擇趨勢雲",
  "home.f6_box_text": "了解更多案例 > ",
  "home.f8_box_title": "不僅提供軟件，還有硬件的智能化升級",
  "home.f8_box_text": "軟硬結合，加速美業門店數字化運營管理",
  "home.f5_box_title1": "為什麼選擇",
  "home.f5_box_title2": "趨勢雲",
  "home.f5_box_text": "深入業務細節，技術賦能美業商業模式運營落地",
  "home.learnMore": "了解更多 >",
  "home.f9_box_title": "趨勢美業最新動態",
  "home.more": "更多",
  "home.noArticleList": "暫無相關文章",
  "home.bannerData_title1": "趨勢美業",
  "home.bannerData_subtitle1": "美業連鎖全棧式數字化經營解決方案",
  "home.bannerData_content1_1": "連鎖門店店務管理+多觸點私域運營+人工智能物聯網賦能，",
  "home.bannerData_content1_2": "一站式搞定美業連鎖門店運營管理",
  "home.bannerData_button1": "立即試用",
  "home.bannerData_title2": "美業連鎖機構數字化轉型口碑之選",
  "home.bannerData_subtitle2": "已幫助超1000家美業門店實現數字化轉型、快速盈利",
  "home.bannerData_button2": "·查看詳情",
  "home.dataF2_title1": "美業小程序",
  "home.dataF2_content1": "鏈接客戶，流量變現",
  "home.dataF2_title2": "美業連鎖管理",
  "home.dataF2_content2": "多級連鎖，加盟管理",
  "home.dataF2_title3": "美業分銷",
  "home.dataF2_content3": "全民分銷，業績增長",
  "home.dataF2_title4": "美業AIOT",
  "home.dataF2_content4": "黑科技美業",
  "home.f8_wrap_h1": "測膚儀",
  "home.f8_wrap_p1": "膚質檢測API嵌入測膚儀解讀膚質狀態，推薦護理方案，提高客戶付費意願。",
  "home.f8_wrap_h2": "臺式收銀機",
  "home.f8_wrap_p2": "高性能收銀終端/專業芯片模組告訴運行/穩定流暢、可拓展",
  "home.f8_wrap_h3": "美膚倉",
  "home.f8_wrap_p3": "美白倉/美黑倉/理療機/紫外光療倉等設備聯網，讓操作更智能/更便捷",
  "home.f8_wrap_h4": "按摩儀",
  "home.f8_wrap_p4": "對接按摩儀/麵罩燈/紫外消毒車設備，實現設備聯網",
  "home.f8_wrap_h5": "小票打印機",
  "home.f8_wrap_p5": "高性能精工熱敏打印頭支持海量訂單打印，操作維護省錢更省心",
  "home.f8_wrap_h6": "機器人",
  "home.f8_wrap_p6": "體態檢測機器人/中醫體質機器人，適用多種場景，黑科技觸點為門店帶來源源不斷客流",
  "home.dataF4_title1": "預約排班",
  "home.dataF4_content1": "便捷化體驗，智能化管理",
  "home.dataF4_title2": "在線預約：",
  "home.dataF4_content2": "顧客線上自助預約時間、技師，體驗滿意，到店率高",
  "home.dataF4_title3": "預約看闆",
  "home.dataF4_title4": "排班管理",
  "home.dataF4_title5": "房間管理",
  "home.dataF4_title6": "獲客引流",
  "home.dataF4_content6": "線上線下多渠道引流獲客",
  "home.dataF4_title7": "小程序商城：",
  "home.dataF4_content7": "微信用戶流量的超級流量入口",
  "home.dataF4_title8": "新人券 / 體驗券",
  "home.dataF4_title9": "AI 測膚 / 體測",
  "home.dataF4_title10": "內容種草",
  "home.dataF4_title11": "成交轉化",
  "home.dataF4_content11": "激活消費，業績提升",
  "home.dataF4_title12": "秒殺：",
  "home.dataF4_content12": "限時限量秒殺，引發沖動消費",
  "home.dataF4_title13": "體驗價",
  "home.dataF4_title14": "限時優惠券",
  "home.dataF4_title15": "活動抽獎",
  "home.dataF4_title16": "留存復購",
  "home.dataF4_content16": "鎖客留客，業績穩步增長",
  "home.dataF4_title17": "儲值卡、醫療卡、套餐卡：",
  "home.dataF4_content17": "豐富卡項設計方案，吸引顧客辦卡充值",
  "home.dataF4_title18": "積分體係",
  "home.dataF4_title19": "RFM智能分析",
  "home.dataF4_title20": "會員權益/會員關係",
  "home.dataF4_title21": "分銷裂變",
  "home.dataF4_content21": "全民帶貨助力業績增長",
  "home.dataF4_title22": "分享券：",
  "home.dataF4_content22": "通過微信贈券，分享好禮，挖掘老客戶背後的人脈",
  "home.dataF4_title23": "拚團",
  "home.dataF4_title24": "砍價助力",
  "home.dataF4_title25": "分銷係統",
  "home.dataF4_title26": "連鎖經營",
  "home.dataF4_content26": "多門店統一管理，標準化快速擴張品牌",
  "home.dataF4_title27": "權限設置：",
  "home.dataF4_content27": "微信用戶流量的超級流量入口",
  "home.dataF4_title28": "資源共享",
  "home.dataF4_title29": "營銷規劃",
  "home.dataF4_title30": "財務監控",
  "home.dataF4_title31": "數據報錶",
  "home.dataF4_content31": "多維度經營數據分析，賦能精細化運營",
  "home.dataF4_title32": "會員報錶：",
  "home.dataF4_content32": "會員卡耗、會員統計、會員畫像一目了然",
  "home.dataF4_title33": "業績報錶",
  "home.dataF4_title34": "連鎖報錶",
  "home.dataF4_title35": "決策報錶",
  "home.dataF5_title1": "深刻理解美業品牌經營核心",
  "home.dataF5_content1": "連接一切美業品牌經營要素，從會員、店務、營銷、供應鏈一體化解決方案，真正一站式解決方案",
  "home.dataF5_title2": "海量客戶積纍，持續優化升級",
  "home.dataF5_content2": "海量客戶積纍，服務客戶覆蓋美妝、美容醫院、中醫診療、齒科診所、舞蹈健身等美業類型",
  "home.dataF5_title3": "私域運營賦能門店增長獲客",
  "home.dataF5_content3": "功能齊全的美業小程序，賦能品牌搞定拓-留-鎖-升，鏈接客戶，實現流量爆炸式增長與變現",
  "home.dataF5_title4": "17年行業經驗強大品牌保障",
  "home.dataF5_content4": "強大穩定的產品研發團隊，傾心陪伴，支持業務定製，滿足各類功能各類場景的個性化需求",
  
  "home.dataF6_list1_obj1_name1": "陽光醫美",
  "home.dataF6_list1_obj1_content1": "全國５Ａ級醫療美容機構，華南高端醫美品牌， 醫美行業龍頭企業",
  "home.dataF6_list1_obj1_content1_2": ' 「通過數字化手段，以社區種草、醫美論壇、智慧供應鏈三大手法持續打造品牌數智零售業態。」 ',
  "home.dataF6_list1_obj1_iconText1": "供應鏈",
  "home.dataF6_list1_obj1_iconText1_2": "種草社區",
  "home.dataF6_list1_obj1_iconText1_3": "醫美論壇",

  "home.dataF7_list1_obj1_name1": "華邦製藥",
  "home.dataF7_list1_obj1_content1": "國內皮膚臨床用藥及皮膚健康領域的龍頭企業,擁有國內最完整的皮膚類藥物產品線",
  "home.dataF7_list1_obj1_content1_2": ' 「抓住「AI+皮膚藥」新增長點，基於趨勢雲AI算法的優 勢，實現AI技術與業務邏輯的完美融合。」 ',
  "home.dataF7_list1_obj1_iconText1": "AI測膚",
  "home.dataF7_list1_obj1_iconText1_2": "美麗商城",
  "home.dataF7_list1_obj1_iconText1_3": "膚質檔案",

  "home.dataF6_list1_obj1_name2": "韓妃醫美",
  "home.dataF6_list1_obj1_content2": "上市醫美，廣東TOP整形美容三級醫院，實現 全國4城6院品牌連鎖規模化經營",
  "home.dataF6_list1_obj1_content2_2": ' 「深度圍繞企業數字化佈局，搭建健康美容垂直電商生態平臺，實現品牌業績增長新突破。」 ',
  "home.dataF6_list1_obj1_iconText2": "連鎖＋微店",
  "home.dataF6_list1_obj1_iconText2_2": "服務預約",
  "home.dataF6_list1_obj1_iconText2_3": "在線直播",

  "home.dataF6_list1_obj1_name3": "洗臉貓",
  "home.dataF6_list1_obj1_content3": "創新打造「輕美容洗臉吧」，專註麵部洗護服務 ，全國門店已超500家",
  "home.dataF6_list1_obj1_content3_2": ' 「以』輕美容+新零售』重新定義經營場景，打通線上線下渠道，幫助品牌實現快速招商加盟。」 ',
  "home.dataF6_list1_obj1_iconText3": "線上商城",
  "home.dataF6_list1_obj1_iconText3_2": "會員分銷",
  "home.dataF6_list1_obj1_iconText3_3": "代理商管理",

  "home.dataF6_list1_obj1_name4": "覓光",
  "home.dataF6_list1_obj1_content4": "騰訊、小米、真格基金等資本戰略入股，國內美容 儀器TOP新消費品牌",
  "home.dataF6_list1_obj1_content4_2": ' 通過AI技術升級產品體驗，打破傳統營銷睏局，月銷額持續增長，創造超3億的業績神話。" ',
  "home.dataF6_list1_obj1_iconText4": "AI測膚",
  "home.dataF6_list1_obj1_iconText4_2": "商品推薦",
  "home.dataF6_list1_obj1_iconText4_3": "皮膚檔案",

  "home.dataF6_list1_obj2_name1": "漾瀾",
  "home.dataF6_list1_obj2_content1": "瑞士科技護膚品牌，與Clinique La Prairie(CLP)  長期合作，以技術領先全球抗衰老領域",
  "home.dataF6_list1_obj2_content1_2": ' 「用』分銷』實現大範圍覆蓋、高精準鎖定，幫助品牌快速觸達客戶，引爆品牌業績新增量。」 ',
  "home.dataF6_list1_obj2_iconText1": "APP商城",
  "home.dataF6_list1_obj2_iconText1_2": "商學院",
  "home.dataF6_list1_obj2_iconText1_3": "二級分銷",

  "home.dataF6_list1_obj2_name2": "美眾聯",
  "home.dataF6_list1_obj2_content2": "美博會會長馬婭牽頭，30+企業聯合打造的中國 首個美妝產業共享採購平臺",
  "home.dataF6_list1_obj2_content2_2": ' 「打造三方供需配方服務平臺，共享供應鏈資源，提升整體效益，釋放美業強大聚合力。「 ',
  "home.dataF6_list1_obj2_iconText2": "供應鏈係統",
  "home.dataF6_list1_obj2_iconText2_2": "原料商城",
  "home.dataF6_list1_obj2_iconText2_3": "推薦配方",

  "home.dataF6_list1_obj2_name3": "美健集",
  "home.dataF6_list1_obj2_content3": "生物科技護膚品牌，集團以生物科技為根本，佈局大健康生態產業鏈",
  "home.dataF6_list1_obj2_content3_2": ' 「以數字化新引擎，打造智慧美業連鎖加盟商城平臺，構建數字化、規範化服務應用場景。」 ',
  "home.dataF6_list1_obj2_iconText3": "連鎖加盟",
  "home.dataF6_list1_obj2_iconText3_2": "服務預約",
  "home.dataF6_list1_obj2_iconText3_3": "會員分銷",

  "home.dataF6_list1_obj2_name4": "美利康",
  "home.dataF6_list1_obj2_content4": "大型高新光學設備企業，全國光學美容領域領跑者，主營產品為健康美白艙、光動力理療艙、日光浴機（美黑機）",
  "home.dataF6_list1_obj2_content4_2": ' 「設備聯網+硬件智能化升級，助力品牌打造更容易被用戶感知到的科技體驗。」 ',
  "home.dataF6_list1_obj2_iconText4": "AI測膚",
  "home.dataF6_list1_obj2_iconText4_2": "AI體質檢測",
  "home.dataF6_list1_obj2_iconText4_3": "設備聯網/控製",

  "home.dataF6_list1_obj3_name1": "潤澤",
  "home.dataF6_list1_obj3_content1": "提供祛斑、祛皺高端美容服務，主打科技護膚，目前 線下已有200家門店",
  "home.dataF6_list1_obj3_content1_2": ' 「深度圍繞品牌需求，打造私域運營矩陣+門店聯營一站式解決方案，為線下門店賦能。」 ',
  "home.dataF6_list1_obj3_iconText1": "AI檢測",
  "home.dataF6_list1_obj3_iconText1_2": "預約服務",
  "home.dataF6_list1_obj3_iconText1_3": "門店聯營",

  "home.dataF7_title1": "膚質檢測",
  "home.dataF7_content1": "配合攝像頭，從膚色、膚齡、毛孔光滑度等多角度分析出膚質狀況，並依據分析結果，智能推薦一對一美麗方案",
  "home.dataF7_title2": "體質檢測",
  "home.dataF7_content2": "智能機器人實現望-聞-問-切中醫四診，快速辨別中醫體質，推薦養生方案",
  "home.dataF7_title3": "體型檢測",
  "home.dataF7_content3": "獲得人體核心關鍵點坐標、通過計算肩部、脊柱、骨盆、腿部等體態數據，快速給到用戶專業的體態報告",
  "home.articleCate_name1": "産品動態",
  "home.articleCate_name2": "簽約動態",
  "home.articleCate_name3": "客戶案例",
  "home.articleCate_label1": "專註 創新 極緻",
  "home.articleCate_label2": "合作 共贏 發展",
  "home.articleCate_label3": "品質 口碑 趨勢",

  "article.currentLocation": "當前位置：",
  "article.articlList": "文章列錶",
  "article.articlPlaceholder": "請輸入文章標題",
  "article.readFullArticle": "查看全文",
  "article.nan": "暫無",
  "article.relatedArticl": "相關文章",

  // huanggengsheng
  // 美業小程序
  "MiniProgram.bancontent1": "AI賦能的私域變現神器，深度裂變觸達客戶，智能營銷搞定拓、留、鎖、升",
  "MiniProgram.bancontent2": "高效管理 | 高效獲客 | 高效變現",
  "MiniProgram.box1_content1": "黑科技拓客神器，高效引流裂變",
  "MiniProgram.box1_content2": "基於視覺AI技術，配合攝像頭，從膚色、膚齡、毛孔、光滑度等多角度分析出膚質狀況，並依據分析結果，智能推薦產品，構建從「流量」到「變現」的商業閉環。",
  "MiniProgram.box2_content1": "輸入簡體字點下麵繁體字按鈕進行在線轉換",
  "MiniProgram.box2_content2": "通過API或H5接口等形式，為合作夥伴提供APP、小程序的舌診、膚質檢測線上對接服務，智能推薦產品調理方案，應用場景覆蓋藥店、社區、養老院、旅遊、醫院、月子中心等。",
  "MiniProgram.box3_content1": "專家/服務/商品在線預約，合理優化資源利用",
  "MiniProgram.box3_content2": "商家可在商城發佈可預約美容項目，引導客戶在線自助預約，合理引導門店客流的同時，提高工位的使用率，優化員工資源配置，客戶無需線下排隊等待，體驗感更高。",
  "MiniProgram.box4_title": "LBS定位",
  "MiniProgram.box4_content1": "基於LBS定位，精準引流附近顧客",
  "MiniProgram.box4_content2": "基於LBS定位，增加門店爆光率，輻射門店5公裏，提升客流；幫助用戶快速發現附近門店，幫助商家精準引流附近顧客，結合位置信息快速響應服務及商品，提高用戶體驗。",
  "MiniProgram.box5_content1": "可交互的美業社區分享生態，在私域裏實現商業變現閉環",
  "MiniProgram.box5_content2": "短視頻/圖/文形式筆記觸達客戶，通過內嵌商品鏈接，從內容帶動消費轉化，打造變現閉環；為美容院、理療館、健身房、化妝品、寵物等美業商家提供了全新的營銷拓客體係。",
  "MiniProgram.box6_title": "商城變現",
  "MiniProgram.box6_content1": "把美業門店開到顧客手機裏，搶佔億萬流量紅利",
  "MiniProgram.box6_content2": "打造專屬AI+小程序在線商城，打通線上線下業務，玩轉私域流量，助力美業門店轉型升級智慧新零售；AI測膚、AI體質、AI體態等AI黑科技「Buff」加持，助力商家快速實現流量銷量雙增長。",
  "MiniProgram.box7_title": "商城拖拽",
  "MiniProgram.box7_content1": "所見即所得，更快落地你的商業創意",
  "MiniProgram.box7_content2": "拖拽式自定義裝修，定製+私有化部署，快速響應、敏捷疊代，隨想即現、隨需而變，幫助美業 商家快速搭建美業小程序商城，輕鬆實現不同業務場景。",
  // 美業連鎖
  "MeiyeChain.bancontent1": "全麵支持多種連鎖經營模式，有條不紊加盟擴張",
  "MeiyeChain.bancontent2": "直營連鎖 | 加盟連鎖 | 直營加盟混合連鎖",
  "MeiyeChain.box1_title": "管理連鎖",
  "MeiyeChain.box1_content1": "支持多種連鎖經營模式，直營加盟混營全搞定",
  "MeiyeChain.box1_content2": "總部擁有自定義層級權限管理，支持按總部、省、市、區、門店等區域配置，滿足 直營、加盟、自由等多種連鎖業態的經營需求。",
  "MeiyeChain.box2_title": "門店連鎖",
  "MeiyeChain.box2_content1": "多門店統一管理，規範化經營加速品牌擴張",
  "MeiyeChain.box2_content2": "每個門店都有一個線上商城，總部可以將成熟的商業模式快速賦能給每一家門店，進行 線上營銷、交易、或引流到店。",
  "MeiyeChain.box3_title": "資源連鎖",
  "MeiyeChain.box3_content1": "門店數據互聯互享，對比分析協同發展",
  "MeiyeChain.box3_content2": "商品、庫存、會員、消費、人力互聯互通，總部集中管理、多店共享使用，實現資源共享 和優勢互補，全麵提升門店整體經營效益及品牌影響力。",
  "MeiyeChain.box4_title": "營銷連鎖",
  "MeiyeChain.box4_content1": "營銷方案全局規劃，統一促銷多店聯動",
  "MeiyeChain.box4_content2": "係統設定的拚團、秒殺、砍價、優惠券，儲值、積分、滿減滿贈，拓客小遊戲等營銷 玩法，均可同步給每一個門店，助力門店獲客拉新。",
  "MeiyeChain.box5_title": "會員連鎖",
  "MeiyeChain.box5_content1": "支持門店會員共享，實現經濟效益最大化",
  "MeiyeChain.box5_content2": "總部統一管理會員信息，各門店均可共享總部所有的客戶資源，支持會員跨門店消費， 係統自動識別會員身份、統計核銷，方便各門店清分結算。",
  "MeiyeChain.box6_title": "數據連鎖",
  "MeiyeChain.box6_content1": "運營數據透視分析，輔助管理者運營決策",
  "MeiyeChain.box6_content2": "各門店經營數據互聯共享，可視化數據看闆，方便總部一鍵查看、對比分析，全麵掌控經 營核心數據，製定科學經營管理決策，提升門店運營效率",
  //美業分銷
  "MeiyeDistribution.bantitle": "趨勢美業分銷係統",
  "MeiyeDistribution.bancontent1": "簡單高效起量快，人人帶貨實實在在漲銷量",
  "MeiyeDistribution.bancontent2": "多角色 | 多層級 | 多模式分銷，助力商家搭建分銷渠道拓寬銷路",
  "MeiyeDistribution.box1_title": "分銷模式",
  "MeiyeDistribution.box1_content1": "從0到1，快速搭建你的專屬分銷係統",
  "MeiyeDistribution.box2_title": "特色分銷模式",
  "MeiyeDistribution.box2_content1": "不僅提供係統，還提供全套分銷解決方案",
  "MeiyeDistribution.box2_content2": "支持設置分銷等級、等級權重、不同身份角色權益，以及不同維度的用戶行為所 獲得「成長值」門檻，激勵用戶/代理商積極主動分銷，科學培養粉絲粘性。",
  "MeiyeDistribution.box3_title": "分銷結算",
  "MeiyeDistribution.box3_content1": "第三方權威金融清分係統，實現空中分賬",
  "MeiyeDistribution.box3_content2": "係統自動分賬，筆筆收益看得見，賬麵一目了然，一人也能掌管仟人分銷團隊； 各方所得收益按比例入賬，有效解決手續費、增值稅、企業所得稅等額外費用分攤問題。",
  "MeiyeDistribution.box4_title": "分銷工具",
  "MeiyeDistribution.box4_content1": "多功能分銷係統，用利益和規則幫你聚攏合夥人",
  "MeiyeDistribution.box4_content2": "分享券、任務卡、砍價助力、拚團、海報、鏈接100+分銷工具任意搭配組合， 貼合不同模式需求，節省百萬推廣經費，讓分銷變得簡單、可落地。",
  "MeiyeDistribution.box5_title": "分銷管理",
  "MeiyeDistribution.box5_content1": "全流程跟蹤管理，關註分銷每個環節",
  "MeiyeDistribution.box5_content2": "可以根據業務需求製定相應的代理分傭規則，通過關係鏈條記錄層級關係，對分銷 進行全流程跟蹤；可視化分銷數據看闆，隨時隨地監控管理。",

    // 關於我們
    "AboutUs.Facingthe": "麵嚮全球美業&大健康企業",
    "AboutUs.Provideaone-stop": "提供一站式數字化管理與營銷解決方案",
    "AboutUs.Freeexperience": "免費體驗",
    "AboutUs.TrendMeiye": "趨勢美業與您共創未來趨勢",
    "AboutUs.TrendBeautyprovides": "趨勢美業，專為美業（醫療美容、中醫理療、皮膚管理、舞蹈健身、齒科診所、産後護理、足浴spa、 美體瘦身、美甲美睫、美容美發……）提供一站式運營管理解決方案。從拓客小程序，到連鎖門店管理， 到美業供應鏈係統，以及趨勢雲傾力研發的人工智能+AI算法，以膚質識別、體質識別為切入口解決 行業獲客引流擠持續變現的痛點及需求，結合美業物聯網化解決方案，全麵解決美業品牌商運營需求。",
    "AboutUs.wehaveaccumulated": "截至目前，我們已積纍了包括陽光醫美、韓妃醫美、奈瑞兒、無限極、洗臉貓、覓光、華邦製藥、普羅 亭、RARESEE、力海醫藥、覓齡輕醫美、纖美紀、萊賀思寶（新加坡）、潤澤美業、菠蘿樹、美利康、 漾蘭（瑞士）、芬樂、華亞光電等數仟家企業客戶的成功服務經驗。",
    "AboutUs.GlobalCustomers": "全球客戶",
    "AboutUs.managementefficiency": "管理效率提升",
    "AboutUs.acquisitionefficiency": "獲客效率提升",
    "AboutUs.operationalefficiency": "運營效率提升",
    "AboutUs.Navigationteam": "領航團隊",
    "AboutUs.HuangLiangxing": "黃良興",
    "AboutUs.GeneralManager": "馬來西亞（零售業）上市公司中國區總經理",
    "AboutUs.ListedCompanies": "東南亞（物流業）上市公司中國區總經理",
    "AboutUs.experienceoverdue": "逾期25年市場營銷經驗",
    "AboutUs.DirectorofOperations": "趨勢美業國際事業發展中心運營總監",
    "AboutUs.BusinessSchool": "環球美業健康商學院校監及創始人",
    "AboutUs.Chairman": "香港美容協會主席",
    "AboutUs.Senior": "資深企業家及培訓導師",
    "AboutUs.BeautyIndustry": "趨勢美業運營策略專家",
    "AboutUs.JiaChao": "賈超",
    "AboutUs.ChiefTraditional": "主任中醫師/醫學博士/碩士研究生導師",
    "AboutUs.Academic": "國家非物質文化遺産賈立惠點穴療法學術繼承人",
    "AboutUs.Professor": "名老中醫骨傷學陳基長教授學術繼承人",
    "AboutUs.Technical": "趨勢美業大健康領域技術專家",
    "AboutUs.WenGuihua": "文貴華",
    "AboutUs.Technology": "華南理工大學教授博士生導師",
    "AboutUs.Director": "華南理工機器學習與數據挖掘實驗室主任",
    "AboutUs.Leader": "國內AI體質識別機器人應用領頭人",
    "AboutUs.Member": "趨勢美業AI智能産品開發專家組成員",
    "AboutUs.explorationprocess": "探索曆程",
    "AboutUs.foundedTrend": "汪振俊先生創辦趨勢雲，獲批國家軟件企業；",
    "AboutUs.Awarded": "榮獲全國最佳應用服務商和最佳應用開發商；",
    "AboutUs.Thesupplychain": "供應鏈平臺「多商網」上線，整合十萬供應商資源，佈局線上線下三十萬分銷商渠道",
    "AboutUs.Unitedwith": "聯合華南理工大學人工智能實驗室推出膚質檢測係統；趨勢美業初步孵化",
    "AboutUs.SouthChina": "聯合華南理工大學推出人工智能體態檢測係統、中醫體質檢測係統",
    "AboutUs.TheArtificialIntelligence": "被授牌“廣東省人工智能中醫工程技術研究中心”的“人工智能應用研發基地”",
    "AboutUs.TrendBeauty": "“趨勢美業”SaaS産品借力人工智能算法收割醫美、大健康、中醫診療等泛美業市場",
    "AboutUs.TrendCloud": "趨勢雲（香港）（趨勢雲國際事業發展中心）正式運營，佈局國際市場，為全球美業客戶提供更專業的服務",
    "AboutUs.Embark": "開啓探索之旅",
    "AboutUs.ContactUs": "聯係我們",
    "AboutUs.Guangzhou": "廣州丨總部",
    "AboutUs.ZhongshanAvenue": "廣州市天河區中山大道西89號",
    "AboutUs.WestLadder": "華景軟件園區B棟西梯5樓",
    "AboutUs.HongKong": "香港",
    "AboutUs.Foshan": "佛山",
    "AboutUs.FenjiangMiddle": "佛山市禪城區汾江中路215號",
    "AboutUs.Room": "創業大廈1008室",
    "AboutUs.phone": "電話",
  "AboutUs.mailbox": "郵箱",
    
  // 客戶案例
  "Customer.Advanced": "先進企業實踐案例",
  "Customer.businessgrowth": "美業商業增長的新方式",
  "Customer.Scheme": "方案免費領",
  "Customer.Deepening": "深入核心業務場景，趨勢雲助力奈瑞兒品牌門店提供引流、增收、管理等一站式解決方案",
  "Customer.Narielhas":"奈瑞兒選擇與趨勢雲合作，旨在通過AI黑科技賦能+數字化連鎖運營管理，融合業務場景、營銷場景，搭建起覆蓋“生活美容+醫療美容+大健康”的一站式美麗健康調理中心，助力品牌實現全方位的AI+數字化賦能，實現全域增長。",
  "Customer.completestory": "閱讀完整故事",
  "Customer.Meiye": "美業",
  "Customer.SeekingLight": "覓光：月銷持續“破億”的秘密武器", 
  "Customer.SunshineMedical": "陽光醫美：5A級醫療美容醫院的數字升級路線", 
  "Customer.HuabangPharmaceuticals": "華邦製藥：AI與皮膚臨床藥企的雙劍合璧", 
  "Customer.ShampooCat": "洗臉貓：輕醫美品牌就要用這套 招商玩法", 
  "Customer.Unlimited": "無限極：一個動作讓直銷員更專 業，更穩固", 
  "Customer.Proting": "普羅亭：體檢中心引入AI體質檢測？！", 
  "Customer.LaiheSibao": "萊賀思寶：新加坡萊賀思寶，海外 中醫診所的AI升級戰略", 
  "Customer.Zeran": "澤然：4人小團隊業績增長100w的秘籍", 
  "Customer.Meilikang": "美利康：美業IoT，為客戶提供優質服務體驗", 
  "Customer.RARESEE": "RARESEE：霧化美容儀+AI測膚打造出穩固私域流量池", 
  "Customer.ChenNongfu": "陳農夫：一招搞定300+加盟商", 
  "Customer.OuTinger": "歐婷兒：暴增300w+業績，複購率90%以上", 
  "Customer.Fenle": "芬樂：對標BabyCenter，盤活孕媽用戶", 
  "Customer.PineappleTree": "菠蘿樹：800+母嬰玩具體驗館的.創造力", 
  "Customer.Youkeyhui": "優鍵薈：如何做好60+歲用戶的生意", 
  "Customer.Huamei": "華美：廣州華美與趨勢雲達成合作， 共築「智能中醫美養」新高地",
  "Customer.Theimagecomes": "*圖片來自客戶提供或客戶公開資料/客戶官網，如有侵權請聯係刪除", 
  "Customer.Browsecustomer": "瀏覽客戶案例", 
}
export default zhhk